import "./Screen.scss";

import {
  Occupancy as MOccupancy,
  OccupancyZone as MOccupancyZone,
  Screen as MScreen,
  Zone as MZone,
} from "../../models";
import React, { FunctionComponent, useEffect, useState } from "react";

import { DataStore } from "aws-amplify";
import { Grid } from "@material-ui/core";
import ZoneSmall from "./ZoneSmall";
import { useCallback } from "react";

interface ZonesProps {
  screen: MScreen;
}

export const Overview: FunctionComponent<ZonesProps> = ({ screen }) => {
  const [zones, setZones] = useState<MZone[]>([]);

  const loadZones = useCallback(() => {
    if (!screen) {
      console.log("Screen is not set", "Retry in 500ms...");
      setTimeout(() => loadZones(), 500);
      return;
    }
    DataStore.query(MOccupancyZone).then(async (occ: MOccupancyZone[]) => {
      setZones(
        (await Promise.all(occ.map((o) => o.zone))).sort((a, b) =>
          `${a.sort}-${a.name}`.localeCompare(`${b.sort}-${b.name}`, "de", {
            numeric: true,
          })
        )
      );
    });
  }, [screen]);

  useEffect(() => {
    loadZones();
  }, [loadZones]);

  // subscribe to data strean
  useEffect(() => {
    const subscription = DataStore.observe(MOccupancyZone).subscribe(() =>
      loadZones()
    );
    return () => subscription.unsubscribe();
  }, [loadZones]);
  useEffect(() => {
    const subscription = DataStore.observe(MZone).subscribe(() => loadZones());
    return () => subscription.unsubscribe();
  }, [loadZones]);
  useEffect(() => {
    const subscription = DataStore.observe(MOccupancy).subscribe(() =>
      loadZones()
    );
    return () => subscription.unsubscribe();
  }, [loadZones]);

  return (
    <Grid container spacing={1}>
      {zones.map((zone) => (
        <Grid key={zone.id} item md={3} xs={12}>
          <ZoneSmall zone={zone} />
        </Grid>
      ))}
    </Grid>
  );
};

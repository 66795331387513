import { DatePicker } from "@material-ui/pickers";
import { FieldProps } from "formik";
import { DateTime } from "luxon";

const FormikDatePicker = ({
  form: { setFieldValue },
  field: { name, value },
}: FieldProps<DateTime | undefined>) => {
  return (
    <DatePicker
      name={name}
      clearable
      autoOk
      fullWidth
      cancelLabel="Schließen"
      disablePast={true}
      format="dd.MM.yyyy"
      variant="dialog"
      emptyLabel="Kein Ende"
      clearLabel="Leeren"
      onChange={(value) => {
        setFieldValue(name, value);
      }}
      value={value?.toISO()}
      animateYearScrolling={false}
    />
  );
};

export default FormikDatePicker;

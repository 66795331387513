import { createTheme } from "@material-ui/core";
import shadows from "./shadows";
import typography from "./typography";
import { deDE } from '@material-ui/core/locale';

const theme = createTheme({
  palette: {
    primary: {
      light: "rgb(138, 153, 203)",
      main: "rgb(38, 53, 103)",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#FFFFFF",
      contrastText: "#000",
    },
    background: {
      default: "#444444",
      paper: "#FFFFFF",
    },
    action: {
      hover: "rgb(138, 153, 203)",
      active: "rgb(38, 53, 103)",
      selected: "rgb(38, 53, 103)",
      disabledBackground: "#BABABA",
    },
    success: { main: "#CEE740" },
    divider: "#D3D3D3",
  },
  shadows,
  typography,
},deDE);

export default theme;

import { Chip, makeStyles } from "@material-ui/core";
import { FunctionComponent, useEffect, useState } from "react";
import { OccupancyZone as MOccupancyZone, Zone as MZone } from "../../models";

import { DataStore } from "aws-amplify";
import { sortBy } from "lodash";

const useStyles = makeStyles((theme) => ({
  ul: {},
  li: {
    display: "inline-block",
    marginRight: "12px",
  },
}));

interface OccupanciesZoneViewProps {
  id: string;
}

const getZonesForOccupancy = (id: string): Promise<MZone[]> => {
  return DataStore.query(MOccupancyZone, (sz) => sz.occupancy.id.eq(id)).then(
    async (occupancyZone: MOccupancyZone[]) => {
      return await Promise.all(occupancyZone.map((sz) => sz.zone));
    }
  );
};

const OccupanciesZoneView: FunctionComponent<OccupanciesZoneViewProps> = ({
  id,
}: OccupanciesZoneViewProps) => {
  const classes = useStyles();
  const [zones, setZones] = useState<MZone[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        const s = await getZonesForOccupancy(id);
        const ss = sortBy(s, "name");
        setZones(ss);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, [id]);
  if (loading) return <p>Loading...</p>;
  return (
    <ul className={classes.ul}>
      {zones.map((s, i) => (
        <li key={i} className={classes.li}>
          <Chip color="secondary" label={s.name} />
        </li>
      ))}
    </ul>
  );
};

export default OccupanciesZoneView;

import { DateTime } from "luxon";
import { Grid, Paper } from "@material-ui/core";
import { TodoProject } from "../../models";

import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { Todo as MTodo } from "../../models";
import styled from "styled-components";
import { DataStore } from "aws-amplify";
import { orderBy } from "lodash";
import { useSnackbar } from "notistack";

interface TodoProjectItemProps {
  todoproject: TodoProject;
  handleRowClick?: (todoproject: TodoProject) => void;
}

const SPM = styled.div`
  text-align: center !important;
  color: #222200;
  font-weight: bold;
  text-align: left;
  font-size: 21px;
  line-height: 21px;
  min-height: 21px;
  // padding: 0px 21px 21px 21px;
`;

const SPT = styled.div`
  text-align: center !important;
  color: #666666;
  text-align: left;
  font-size: 18px;
  line-height: 18px;
  width: 100%;
`;

const SPTT = styled.div<{ done?: boolean }>`
  text-align: left !important;
  font-size: 20px;
  line-height: 22px;
  font-weight: bold;
  width: 100%;
  color: #222222;
  padding-left: 27px;
  text-indent: -27px;
  padding-bottom: 0.5em;
  &:before {
    content: "";
    display: inline-block;
    width: 14px;
    height: 14px;
    margin-right: 0.5em;
    border-radius: 50%;
    border: 2px solid #ddd;
    background-color: ${(props) => (props.done ? "#79ff4c" : "#ff4f4c")};
  }
`;

const CPapger = styled(Paper)<{
  hasOK: boolean;
  hasWarning: boolean;
  hasDanger: boolean;
}>`
  background-color: #fff;
  border: 8px solid #fff;
  border-color: ${(props) => (props.hasOK ? "#79ff4c" : "")};
  border-color: ${(props) => (props.hasWarning ? "#eba134" : "")};
  border-color: ${(props) => (props.hasDanger ? "#ff4f4c" : "")};
  margin-bottom: 24px;
  padding: 21px;
`;

const TodoProjectItem: FunctionComponent<TodoProjectItemProps> = (props) => {
  const [todos, setTodos] = useState<MTodo[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { enqueueSnackbar } = useSnackbar();

  const loadData = useCallback(async () => {
    try {
      setLoading(true);
      const s = await DataStore.query(MTodo, (t) =>
        t.project.id.eq(props.todoproject.id)
      );
      setTodos(orderBy(s, ["sort"], ["asc"]));
      console.log(s.map((t) => t.done));
    } catch (error) {
      if (error instanceof Error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } else {
        console.log("Unexpected error", error);
      }
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar, props.todoproject.id]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  // subscribe to data strean
  const [subscription, setSubscription] = useState<any>();
  useEffect(() => {
    if (subscription !== undefined) {
      console.log("Unsubscribe from todos");
      subscription.unsubscribe();
    }
    const _subscription = DataStore.observe(MTodo).subscribe(() => loadData());
    setSubscription(_subscription);
    return () => {
      if (subscription !== undefined) {
        console.log("Unsubscribe from todos late");
        subscription.unsubscribe();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadData]);

  const outAtDT = DateTime.fromISO(props.todoproject.outAt);
  const outAt = outAtDT.toLocaleString({
    weekday: "short",
    month: "short",
    day: "numeric",
  });

  const hasWarning =
    props.todoproject.outAt !== null &&
    outAtDT < DateTime.now().plus({ days: 14 });

  const hasDanger =
    props.todoproject.outAt !== null &&
    outAtDT < DateTime.now().plus({ days: 7 });

  return (
    <CPapger
      hasWarning={hasWarning}
      hasDanger={hasDanger}
      hasOK={false}
      variant="elevation"
      onClick={() =>
        props.handleRowClick && props.handleRowClick(props.todoproject)
      }
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <SPT>Projekt</SPT>
          <SPM>{props.todoproject.title}</SPM>
        </Grid>
        <Grid item xs={12}>
          <SPT>Nummer</SPT>
          <SPM>{props.todoproject.subtitle}</SPM>
        </Grid>
        <Grid item xs={12}>
          <SPT>Projektleiter</SPT>
          <SPM>{props.todoproject.lead}</SPM>
        </Grid>
        <Grid item xs={12}>
          <SPT>Deadline</SPT>
          <SPM>{outAt}</SPM>
        </Grid>
        <Grid item xs={12}>
          <hr />
        </Grid>
        {todos.map((todo) => (
          <Grid item xs={12} todo-id={todo.id} todo-sort={todo.sort}>
            <SPTT done={todo.done}>{todo.title}</SPTT>
          </Grid>
        ))}
      </Grid>
    </CPapger>
  );
};

export default TodoProjectItem;

import { Grid, Paper, Typography, makeStyles } from "@material-ui/core";
import React, { FunctionComponent } from "react";

import { DateTime } from "luxon";
import { Occupancy as MOccupancy } from "../../models";
import OccupanciesZoneView from "./OccupanciesZoneView";

interface OccupancyItemProps {
  occupancy: MOccupancy;
  handleRowClick?: (occupancy: MOccupancy) => void;
}

const colors = {
  reserved: "#00b0ff",
  packing: "#CEE740",
  complete: "#00b000",
  missing: "#ff4f4c",
  returned: "#ffa41b",
  received: "#c200a2",
  parked: "#717171",
};

const s = {
  RESERVED: "Reserviert",
  PACKING: "Packen",
  COMPLETE: "Vollständig",
  MISSING: "Matr. fehlt",
  RETURNED: "Verräumen",
  RECEIVED: "Wareneingang",
  PARKED: "Abgestellt",
};

const useStyles = makeStyles((theme) => ({
  RESERVED: { padding: theme.spacing(3), background: colors.reserved },
  PACKING: { padding: theme.spacing(3), background: colors.packing },
  COMPLETE: { padding: theme.spacing(3), background: colors.complete },
  MISSING: { padding: theme.spacing(3), background: colors.missing },
  RETURNED: { padding: theme.spacing(3), background: colors.returned },
  RECEIVED: { padding: theme.spacing(3), background: colors.received },
  PARKED: { padding: theme.spacing(3), background: colors.parked },
}));

const OccupancyItem: FunctionComponent<OccupancyItemProps> = (props) => {
  const classes = useStyles();
  const className = classes?.[props.occupancy.status];
  return (
    <Paper
      onClick={() =>
        props.handleRowClick && props.handleRowClick(props.occupancy)
      }
      className={className}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={3}>
          <Typography variant="h1" component="h1" color="primary">
            {props.occupancy.title}
          </Typography>
          <Typography variant="h3" component="h3" color="secondary">
            {props.occupancy.description}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} lg={5}>
          <OccupanciesZoneView id={props.occupancy.id} />
        </Grid>
        <Grid item xs={12} md={6} lg={2}>
          {props.occupancy.outAt &&
            DateTime.fromISO(props.occupancy.outAt).toFormat("dd.MM")}
        </Grid>
        <Grid item xs={12} md={6} lg={2}>
          {s?.[props.occupancy.status]}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default OccupancyItem;

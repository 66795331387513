import "./Zone.scss";
import "@drewbot/sass-flexbox-grid/public/sass-flexbox/main.min.css";

import {
  Occupancy as MOccupancy,
  OccupancyZone as MOccupancyZone,
  Zone as MZone,
  OccupancyStatus,
} from "../../models";
import React, { useCallback, useEffect, useState } from "react";

import { DataStore } from "aws-amplify";
import { DateTime } from "luxon";
import classnames from "classnames";

interface ZoneProps {
  zone: MZone;
  hideDesc?: boolean;
}

function getStatusText(status: OccupancyStatus | keyof typeof OccupancyStatus) {
  if (status === "COMPLETE") return "VOLLSTÄNDIG";
  if (status === "RESERVED") return "RESERVIERT";
  if (status === "PACKING") return "PACKEN";
  if (status === "RETURNED") return "VERRÄUMEN";
  if (status === "RECEIVED") return "WARENEINGANG";
  if (status === "PARKED") return "ABGESTELLT";
  if (status === "MISSING") return "MATR. FEHLT";
  return "";
}

function Zone({ zone, hideDesc }: ZoneProps) {
  const [occupancy, setOccupancy] = useState<MOccupancy>();
  const loadZones = useCallback(() => {
    DataStore.query(MOccupancyZone, (oz) => oz.zone.id.eq(zone.id)).then(
      async (occupancies: MOccupancyZone[]) => {
        setOccupancy(
          occupancies && occupancies.length > 0
            ? await occupancies[0].occupancy
            : undefined
        );
      }
    );
  }, [zone.id]);

  useEffect(() => {
    const subscription = DataStore.observe(MOccupancyZone).subscribe((oz) => {
      loadZones();
    });
    return () => subscription.unsubscribe();
  }, [loadZones]);

  useEffect(() => {
    const subscription = DataStore.observe(MOccupancy).subscribe((oz) => {
      loadZones();
    });
    return () => subscription.unsubscribe();
  }, [loadZones]);

  useEffect(() => {
    // initial
    loadZones();
  }, [loadZones]);

  const zoneClasses = classnames("zone", {
    empty: !occupancy,
    complete: occupancy && occupancy.status === "COMPLETE",
    reserved: occupancy && occupancy.status === "RESERVED",
    packing: occupancy && occupancy.status === "PACKING",
    returned: occupancy && occupancy.status === "RETURNED",
    received: occupancy && occupancy.status === "RECEIVED",
    parked: occupancy && occupancy.status === "PARKED",
    missing: occupancy && occupancy.status === "MISSING",
  });

  return (
    <div className={zoneClasses}>
      <div className="zone-header">
        <p className="zone-name">{zone.name}</p>
        <p className="zone-status">
          {occupancy && getStatusText(occupancy.status)}
        </p>
      </div>
      {!occupancy && <p className="zone-body">Zone ist leer</p>}
      {occupancy && (
        <div className="zone-body">
          <div className="row">
            <div className="col-xs-9">{occupancy.title}</div>
            <div className="col-xs-3">
              {occupancy.outAt &&
                DateTime.fromISO(occupancy.outAt).toFormat("'Bis:' dd.MM")}
            </div>
            <div className="col-xs-12">{occupancy.description}</div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Zone;

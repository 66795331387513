import "./App.scss";

import { FunctionComponent } from "react";
import GlobalStyles from "./components/GlobalStyles";
import LuxonUtils from "@date-io/luxon";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { SnackbarProvider } from "notistack";
import { ThemeProvider } from "@material-ui/core";
import routes from "./routes";
import theme from "./theme";
import { useRoutes } from "react-router-dom";

/**
 * Returns a component App
 *
 * @returns {FunctionComponent<null>}
 * @example
 * <App />
 */
const App: FunctionComponent<null> = () => {
  const routing = useRoutes(routes);
  return (
    <MuiPickersUtilsProvider utils={LuxonUtils} locale="de">
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          maxSnack={3}
          autoHideDuration={3000}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <GlobalStyles />
          {routing}
        </SnackbarProvider>
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
};

export default App;

import * as Yup from "yup";

import {
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  Paper,
  Select,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Field, FieldArray, FieldProps, Formik, FormikHelpers } from "formik";
import {
  Occupancy as MOccupancy,
  OccupancyZone as MOccupancyZone,
  Zone as MZone,
  OccupancyStatus,
} from "../../models";
import React, { FunctionComponent, useEffect, useState } from "react";

import CancelIcon from "@material-ui/icons/Cancel";
import { DataStore } from "aws-amplify";
import { DateTime } from "luxon";
import FormikDatePicker from "../../components/FormikDatePicker";
import Page from "../../components/Page";
import SaveIcon from "@material-ui/icons/Save";
import { TextField } from "@material-ui/core";
import prepareAPIError from "../../utils/prepareAPIError";
import { useNavigate } from "react-router";
import { useSnackbar } from "notistack";

interface FormValues {
  title: string;
  description?: string;
  outAt?: DateTime;
  status: OccupancyStatus | keyof typeof OccupancyStatus;
  zones: string[];
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  stack: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "40px",
  },
  paper: {
    width: 200,
    height: 230,
    overflow: "auto",
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
  card: {
    width: 240,
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  input: {
    // padding: theme.spacing(0.5, 2),
  },
  list: {
    width: "100%",
    height: 230,
    backgroundColor: theme.palette.background.paper,
    overflow: "auto",
  },
  content: {
    borderBottom: "1px solid rgb(224, 224, 224)",
    textAlign: "left",
    padding: "16px",
  },
  email_verified: {
    marginLeft: "16px",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  name: {
    lineHeight: "48px",
  },
  zone: {
    float: "left",
  },
}));

const OccupancyCreate: FunctionComponent = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [av_zones, setAVZones] = useState<MZone[] | undefined>();
  const [usedZones, setUsedZones] = useState<string[]>();

  useEffect(() => {
    const load = async () => {
      DataStore.query(MZone).then((zones: MZone[]) => {
        setAVZones(
          zones.sort((a, b) =>
            `${a.sort}-${a.name}`.localeCompare(`${b.sort}-${b.name}`, "de", {
              numeric: true,
            })
          )
        );
      });
      DataStore.query(MOccupancyZone)
        .then((occupancyZone: MOccupancyZone[]) => {
          return Promise.all(
            occupancyZone.map(async (sz) => (await sz.zone).id)
          );
        })
        .then((s) => setUsedZones(s));
    };
    load();
  }, []);

  const save = async (v: FormValues, actions: FormikHelpers<FormValues>) => {
    try {
      actions.setSubmitting(true);
      const res = await DataStore.save(
        new MOccupancy({
          title: v.title,
          description: v.description,
          outAt: v.outAt?.toISO(),
          status: v.status,
        })
      );
      if (av_zones) {
        await Promise.all(
          v.zones.map((id) => {
            const zone = av_zones.find((a) => a.id === id);
            if (!zone) throw new Error("Zone not found");
            return DataStore.save(
              new MOccupancyZone({
                occupancy: res,
                zone,
              })
            );
          })
        );
      }
      enqueueSnackbar("Änderungen wurden gespeichert!", { variant: "success" });
      actions.resetForm();
      navigate(`/packzonen/occupancys/${res.id}`);
    } catch (error) {
      const errorMessage = prepareAPIError(error);
      enqueueSnackbar(errorMessage, { variant: "error" });
    } finally {
      actions.setSubmitting(false);
    }
  };

  console.log(av_zones);

  return (
    <Formik
      enableReinitialize
      initialValues={
        {
          title: "",
          description: "",
          outAt: undefined,
          status: OccupancyStatus.RESERVED,
          zones: [],
        } as FormValues
      }
      validationSchema={Yup.object().shape({
        title: Yup.string().required("Name is required").min(4),
        zones: Yup.array().min(1),
      })}
      onSubmit={(values, actions) => {
        save(values, actions);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        isValid,
      }) => (
        <Page
          loading={isSubmitting}
          className={classes.root}
          title="Neue Belegung"
          breadcrumbs={[]}
          actions={[
            {
              title: "Abbruch",
              icon: <CancelIcon />,
              action: () => navigate(`/packzonen`),
              disabled: isSubmitting || !isValid,
            },
            {
              title: "Speichern",
              icon: <SaveIcon />,
              color: "primary",
              action: () => handleSubmit(),
              disabled:
                isSubmitting || !isValid || Object.keys(touched).length === 0,
            },
          ]}
        >
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <Paper>
                <div className={classes.content}>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <Typography color="primary" className={classes.name}>
                        Projekt
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        disabled={isSubmitting}
                        className={classes.input}
                        error={Boolean(touched.title && errors.title)}
                        fullWidth
                        helperText={touched.title && errors.title}
                        name="title"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        value={values.title}
                        variant="standard"
                        required
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <Typography color="primary" className={classes.name}>
                        Beschreibung
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        disabled={isSubmitting}
                        className={classes.input}
                        error={Boolean(
                          touched.description && errors.description
                        )}
                        fullWidth
                        helperText={touched.description && errors.description}
                        name="description"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        value={values.description}
                        variant="standard"
                        required
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <Typography color="primary" className={classes.name}>
                        Bis
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Field component={FormikDatePicker} name="outAt" />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <Typography color="primary" className={classes.name}>
                        Status
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Select
                        disabled={isSubmitting}
                        className={classes.input}
                        error={Boolean(touched.status && errors.status)}
                        fullWidth
                        name="status"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        value={values.status}
                        variant="standard"
                        required
                      >
                        <MenuItem value={OccupancyStatus.RESERVED}>
                          Reserviert
                        </MenuItem>
                        <MenuItem value={OccupancyStatus.PACKING}>
                          Packen
                        </MenuItem>
                        <MenuItem value={OccupancyStatus.COMPLETE}>
                          Vollständig
                        </MenuItem>
                        <MenuItem value={OccupancyStatus.MISSING}>
                          Matr. fehlt
                        </MenuItem>
                        <MenuItem value={OccupancyStatus.RECEIVED}>
                          Wareneingang
                        </MenuItem>
                        <MenuItem value={OccupancyStatus.RETURNED}>
                          Verräumen
                        </MenuItem>
                        <MenuItem value={OccupancyStatus.PARKED}>
                          Abgestellt
                        </MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                </div>
              </Paper>
            </Grid>
            <Grid item md={6} xs={12}>
              <Paper>
                <div className={classes.content}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography color="primary" className={classes.name}>
                        Zone
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      {/* {errors.zones && (
                        <p>Es muss mindestens eine Zone gewählt sein</p>
                      )} */}
                      <Grid container role="group">
                        <FieldArray
                          name="zones"
                          render={() =>
                            av_zones ? (
                              av_zones.map((avs, index) => (
                                <Grid
                                  item
                                  xs={12}
                                  md={6}
                                  lg={4}
                                  key={index}
                                  className={classes.zone}
                                >
                                  <Field type="checkbox" name="zones">
                                    {({
                                      field: { name, value, onChange, onBlur },
                                    }: FieldProps) => (
                                      <>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              disabled={Boolean(
                                                usedZones?.includes(avs.id)
                                              )}
                                              checked={value.includes(avs.id)}
                                              onChange={onChange}
                                              onBlur={onBlur}
                                              name={name}
                                              value={avs.id}
                                              color="primary"
                                            />
                                          }
                                          label={avs.name || avs.id}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </Grid>
                              ))
                            ) : (
                              <></>
                            )
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </Paper>
            </Grid>
          </Grid>
        </Page>
      )}
    </Formik>
  );
};

export default OccupancyCreate;

import DashboardLayout from "./layouts/DashboardLayout";
import KioskScreen from "./views/kiosk/Screen";
import { Navigate } from "react-router-dom";
import OccupanciesView from "./views/occupancies/OccupanciesView";
import OccupancyCreate from "./views/occupancies/OccupancyCreate";
import OccupancyEdit from "./views/occupancies/OccupancyEdit";
import ProjectCreate from "./views/projects/ProjectCreate";
import ProjectEdit from "./views/projects/ProjectEdit";
import ProjectsView from "./views/projects/ProjectsView";
import ScreenEdit from "./views/screens/ScreenEdit";
import ScreenView from "./views/screens/ScreenView";
import ScreensView from "./views/screens/ScreensView";
import ZoneCreate from "./views/zones/ZoneCreate";
import ZoneEdit from "./views/zones/ZoneEdit";
import ZoneView from "./views/zones/ZoneView";
import ZonesView from "./views/zones/ZonesView";

import TodosCreate from "./views/todos/ProjectCreate";
import TodosEdit from "./views/todos/ProjectEdit";
import TodosView from "./views/todos/TodoProjectsView";
import TodosTemplateEdit from "./views/todos/TodoProjectsView";
import TodosTemplateCreate from "./views/todos/TodoProjectsView";

const routes = [
  {
    path: "/kiosk",
    element: <KioskScreen />,
  },
  {
    path: "/packzonen",
    element: <DashboardLayout />,
    children: [
      { path: "/packzonen/", element: <OccupanciesView /> },
      { path: "/packzonen/o/new", element: <OccupancyCreate /> },
      { path: "/packzonen/o/:id", element: <OccupancyEdit /> },
      { path: "/packzonenzones", element: <ZonesView /> },
      { path: "/packzonenzones/new", element: <ZoneCreate /> },
      { path: "/packzonenzones/:id", element: <ZoneView /> },
      { path: "/packzonenzones/:id/edit", element: <ZoneEdit /> },
      { path: "/packzonenscreens", element: <ScreensView /> },
      { path: "/packzonenscreens/:id", element: <ScreenView /> },
      { path: "/packzonenscreens/:id/edit", element: <ScreenEdit /> },
    ],
  },
  {
    path: "/projekte",
    element: <DashboardLayout />,
    children: [
      { path: "/projekte/", element: <ProjectsView /> },
      { path: "/projekte/o/new", element: <ProjectCreate /> },
      { path: "/projekte/o/:id", element: <ProjectEdit /> },
    ],
  },
  {
    path: "/todos",
    element: <DashboardLayout />,
    children: [
      { path: "/todos/", element: <TodosView /> },
      { path: "/todos/o/new", element: <TodosCreate /> },
      { path: "/todos/o/:id", element: <TodosEdit /> },
      { path: "/todos/t/new", element: <TodosTemplateCreate /> },
      { path: "/todos/t/:id", element: <TodosTemplateEdit /> },
    ],
  },
  {
    path: "/admin",
    element: <DashboardLayout />,
    children: [
      { path: "/admin/screens/", element: <ScreensView /> },
      { path: "/admin/zones", element: <ZonesView /> },
    ],
  },
  { path: "*", element: <Navigate to="/packzonen" /> },
];

export default routes;

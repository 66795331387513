import * as Yup from "yup";

import { Field, FieldArray, Formik, FormikHelpers } from "formik";
import {
  Checkbox,
  Grid,
  Paper,
  Typography,
  makeStyles,
} from "@material-ui/core";

import { FunctionComponent, useEffect, useState } from "react";

import CancelIcon from "@material-ui/icons/Cancel";
import { DataStore } from "aws-amplify";
import { DateTime } from "luxon";
import FormikDateTimePicker from "../../components/FormikDateTimePicker";
import Page from "../../components/Page";
import SaveIcon from "@material-ui/icons/Save";
import { TextField } from "@material-ui/core";
import prepareAPIError from "../../utils/prepareAPIError";
import { useNavigate } from "react-router";
import { useSnackbar } from "notistack";
import { TodoProject as MTodoProject, Todo as MTodo } from "../../models";
import FormikDatePicker from "../../components/FormikDatePicker";

type FormValues = Pick<MTodoProject, "title" | "subtitle" | "lead"> & {
  outAt: DateTime;
  todos: {
    title: string;
    checked: boolean;
    readyOnly: boolean;
  }[];
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  stack: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "40px",
  },
  paper: {
    width: 200,
    height: 230,
    overflow: "auto",
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
  card: {
    width: 240,
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  input: {
    // padding: theme.spacing(0.5, 2),
  },
  list: {
    width: "100%",
    height: 230,
    backgroundColor: theme.palette.background.paper,
    overflow: "auto",
  },
  content: {
    borderBottom: "1px solid rgb(224, 224, 224)",
    textAlign: "left",
    padding: "16px",
  },
  email_verified: {
    marginLeft: "16px",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  name: {
    lineHeight: "48px",
  },
  zone: {
    float: "left",
  },
}));

const TodoProjectCreate: FunctionComponent = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [av_zones, setAVZones] = useState<MTodoProject[] | undefined>();

  useEffect(() => {
    const load = async () => {
      DataStore.query(MTodoProject).then((todoprojects: MTodoProject[]) => {
        setAVZones(
          todoprojects.sort((a, b) =>
            `${a.title}-${a.subtitle}`.localeCompare(
              `${b.title}-${b.subtitle}`,
              "de",
              {
                numeric: true,
              }
            )
          )
        );
      });
    };
    load();
  }, []);

  const save = async (v: FormValues, actions: FormikHelpers<FormValues>) => {
    try {
      actions.setSubmitting(true);
      debugger;
      const res = await DataStore.save(
        new MTodoProject({
          title: v.title,
          subtitle: v.subtitle,
          lead: v.lead,
          outAt: v.outAt?.toISO(),
          createdAt: DateTime.local().toISO(),
          updatedAt: DateTime.local().toISO(),
        })
      );

      // save all additional todos
      for (const i in v.todos) {
        const todo = v.todos[i];
        if (todo.checked) {
          await DataStore.save(
            new MTodo({
              title: todo.title,
              sort: parseInt(i),
              done: false,
              project: res,
              createdAt: DateTime.local().toISO(),
              updatedAt: DateTime.local().toISO(),
            })
          );
        }
      }

      enqueueSnackbar("Änderungen wurden gespeichert!", { variant: "success" });
      actions.resetForm();
      navigate(`/todos/o/${res.id}`);
    } catch (error) {
      const errorMessage = prepareAPIError(error);
      enqueueSnackbar(errorMessage, { variant: "error" });
    } finally {
      actions.setSubmitting(false);
    }
  };

  console.log(av_zones);

  return (
    <Formik<FormValues>
      enableReinitialize
      initialValues={{
        title: "",
        subtitle: "",
        outAt: DateTime.local(),
        todos: [
          {
            title: "Material geplant",
            checked: true,
            readyOnly: true,
          },
          {
            title: "Zubehörjob angelegt",
            checked: true,
            readyOnly: true,
          },
          {
            title: "Logistik geplant",
            checked: true,
            readyOnly: true,
          },
          {
            title: "Ext. Logistik gebucht",
            checked: false,
            readyOnly: false,
          },
          {
            title: "Personal geplant",
            checked: true,
            readyOnly: true,
          },
          {
            title: "Freelancer gebucht",
            checked: false,
            readyOnly: false,
          },
          {
            title: "Hotels gebucht",
            checked: false,
            readyOnly: false,
          },
          {
            title: "Einkäufe erledigt",
            checked: false,
            readyOnly: false,
          },
          {
            title: "Spezialbauten hergestellt",
            checked: false,
            readyOnly: false,
          },
          {
            title: "Technische Pläne fertig",
            checked: false,
            readyOnly: false,
          },
          {
            title: "Infos an Beteiligte kommuniziert",
            checked: true,
            readyOnly: true,
          },
          {
            title: "Packen freigegeben",
            checked: true,
            readyOnly: true,
          },
        ],
      }}
      validationSchema={Yup.object().shape({
        title: Yup.string().required("Name is required").min(4),
        zones: Yup.array().min(1),
      })}
      onSubmit={(values, actions) => {
        save(values, actions);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        isValid,
      }) => (
        <Page
          loading={isSubmitting}
          className={classes.root}
          title="Neues Projekt"
          breadcrumbs={[]}
          actions={[
            {
              title: "Abbruch",
              icon: <CancelIcon />,
              action: () => navigate(`/todos`),
              disabled: isSubmitting || !isValid,
            },
            {
              title: "Speichern",
              icon: <SaveIcon />,
              color: "primary",
              action: () => handleSubmit(),
              disabled:
                isSubmitting || !isValid || Object.keys(touched).length === 0,
            },
          ]}
        >
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <Paper>
                <div className={classes.content}>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <Typography color="primary" className={classes.name}>
                        Projektname
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        disabled={isSubmitting}
                        className={classes.input}
                        error={Boolean(touched.title && errors.title)}
                        fullWidth
                        helperText={touched.title && errors.title}
                        name="title"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        value={values.title}
                        variant="standard"
                        required
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <Typography color="primary" className={classes.name}>
                        Projektnummer
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        disabled={isSubmitting}
                        className={classes.input}
                        error={Boolean(touched.subtitle && errors.subtitle)}
                        fullWidth
                        helperText={touched.subtitle && errors.subtitle}
                        name="subtitle"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        value={values.subtitle}
                        variant="standard"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <Typography color="primary" className={classes.name}>
                        Projektleiter
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        disabled={isSubmitting}
                        className={classes.input}
                        error={Boolean(touched.lead && errors.lead)}
                        fullWidth
                        helperText={touched.lead && errors.lead}
                        name="lead"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        value={values.lead}
                        variant="standard"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <Typography color="primary" className={classes.name}>
                        Bis
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Field component={FormikDatePicker} name="outAt" />
                    </Grid>
                  </Grid>
                </div>
              </Paper>
            </Grid>
            <Grid item md={6} xs={12}>
              <Paper>
                <div className={classes.content}>
                  <FieldArray
                    name="todos"
                    render={(arrayHelpers) => (
                      <>
                        {values.todos?.map((todo, index) => (
                          <Grid key={index} container spacing={3}>
                            <Grid item xs={1}>
                              <Checkbox
                                name={`todos[${index}].checked`}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                disabled={todo.readyOnly || isSubmitting}
                                checked={todo.checked}
                              />
                            </Grid>
                            <Grid item xs={10}>
                              <label>{todo.title}</label>
                              {/* <input
                                type="hidden"
                                name={`todos[${index}].id`}
                              />
                              <TextField
                                disabled={isSubmitting}
                                className={classes.input}
                                error={Boolean(touched.title && errors.title)}
                                fullWidth
                                helperText={touched.title && errors.title}
                                name={`todos[${index}].title`}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                type="text"
                                value={todo.title}
                                variant="standard"
                                required
                              /> */}
                            </Grid>
                          </Grid>
                        ))}
                      </>
                    )}
                  />
                </div>
              </Paper>
            </Grid>
          </Grid>
        </Page>
      )}
    </Formik>
  );
};

export default TodoProjectCreate;

import { get } from 'lodash';

/**
 * Returns API error message
 *
 * @param {any} error the api error
 * @returns {string} an error message
 */
export default function prepareAPIError(error: unknown): string {
    const message = get(error, 'response.data.message');
    const messageFallback = get(error, 'message');
    if (message) return message;
    if (messageFallback) return messageFallback;
    return 'An unknown error has occurred!';
}

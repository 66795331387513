import "./Screen.scss";

import { Screen as MScreen, ScreenMode } from "../../models";
import React, { FunctionComponent, useEffect, useState } from "react";

import { DataStore } from "aws-amplify";
import { Hub } from "aws-amplify";
import { Overview } from "./Overview";
import Projects from "./Projects";
import { Zones } from "./Zones";
import { makeStyles } from "@material-ui/core";
import { useSearchParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: "flex",
    height: "100%",
    overflow: "hidden",
    width: "100%",
    flexDirection: "column",
    minHeight: "100vh",
  },
  wrapper: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    paddingTop: 64,
    marginBottom: theme.spacing(2),
  },
  contentContainer: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
  },
  content: {
    flex: "1 1 auto",
    height: "100%",
    overflow: "auto",
  },
  footer: {
    padding: theme.spacing(3, 2),
    marginTop: "auto",
  },
}));

const Screen: FunctionComponent<{}> = () => {
  const classes = useStyles();
  const [searchParams] = useSearchParams();

  const deviceID = searchParams.get("rdstoken");
  const [screen, setScreen] = useState<MScreen>();
  const [ready, setReady] = useState(false);

  Hub.listen("datastore", (data) => {
    if (data.payload.event === "ready") {
      setReady(true);
    }
  });

  // registerScreen
  useEffect(() => {
    const registerScreen = async () => {
      if (!deviceID || !ready) return;
      let _screen = await DataStore.query(MScreen, (c) =>
        c.deviceID.eq(deviceID)
      );
      // create if not exists
      if (_screen.length === 0) {
        _screen = [
          await DataStore.save(
            new MScreen({
              deviceID,
              mode: ScreenMode.VIRGIN,
            })
          ),
        ];
      }
      setScreen(_screen[0]);
    };
    registerScreen();
  }, [deviceID, ready]);

  useEffect(() => {
    const subscription = DataStore.observe(MScreen).subscribe((newScreen) => {
      if (newScreen.element.id === screen?.id) {
        setScreen(newScreen.element);
      }
    });
    return () => subscription.unsubscribe();
  }, [deviceID, screen]);

  if (!ready) {
    return (
      <div className={classes.root}>
        <div className={classes.wrapper}>
          <div className={classes.contentContainer}>
            <div className={classes.content}>
              <div className="screen">
                <div className="empty">
                  <h1>Loading...</h1>
                  <h2>{deviceID}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (screen?.mode === "ZONES") {
    return (
      <div className={classes.root}>
        <div className={classes.wrapper}>
          <div className={classes.contentContainer}>
            <div className={classes.content}>
              <div className="screen">
                <Zones screen={screen} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (screen?.mode === "PROJECTS") {
    return (
      <div className={classes.root}>
        <div className={classes.wrapper}>
          <div className={classes.contentContainer}>
            <div className={classes.content}>
              <div className="screen">
                <Projects />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (screen?.mode === "OVERVIEW") {
    return (
      <div className={classes.root}>
        <div className={classes.wrapper}>
          <div className={classes.contentContainer}>
            <div className={classes.content}>
              <div className="screen">
                <Overview screen={screen} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <div className="screen">
              <div className="empty">
                <h1>Dieser Screen wurden nicht konfiguriert!</h1>
                <h2>{deviceID}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Screen;

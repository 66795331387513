import * as Yup from "yup";

import {
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Formik, FormikHelpers } from "formik";
import { Screen as MScreen, ScreenMode } from "../../models";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";

import CancelIcon from "@material-ui/icons/Cancel";
import { DataStore } from "aws-amplify";
import DeleteIcon from "@material-ui/icons/Delete";
import Page from "../../components/Page";
import SaveIcon from "@material-ui/icons/Save";
import { TextField } from "@material-ui/core";
import prepareAPIError from "../../utils/prepareAPIError";
import { useSnackbar } from "notistack";

interface FormValues {
  name?: string;
  mode?: ScreenMode;
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  stack: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "40px",
  },
  paper: {
    width: 200,
    height: 230,
    overflow: "auto",
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
  card: {
    width: 240,
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  input: {
    padding: theme.spacing(0.5, 2),
  },
  list: {
    width: "100%",
    height: 230,
    backgroundColor: theme.palette.background.paper,
    overflow: "auto",
  },
  content: {
    borderBottom: "1px solid rgb(224, 224, 224)",
    textAlign: "left",
    padding: "16px",
  },
  email_verified: {
    marginLeft: "16px",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  name: {
    lineHeight: "48px",
  },
}));
const ScreenEdit: FunctionComponent = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();

  const [screen, setScreen] = useState<MScreen | undefined>();
  const [loading, setLoading] = useState<boolean>(true);

  const save = async (v: FormValues, actions: FormikHelpers<FormValues>) => {
    try {
      if (!screen) return;
      actions.setSubmitting(true);
      const res = await DataStore.save(
        MScreen.copyOf(screen, (updated) => {
          updated.name = v.name ? v.name.trim() : updated.name;
          updated.mode = v.mode || updated.mode;
        })
      );
      setScreen(res);
      enqueueSnackbar("Änderungen wurden gespeichert!", { variant: "success" });
      actions.resetForm();
      navigate(`/packzonen/screens/${res.id}`);
    } catch (error) {
      const errorMessage = prepareAPIError(error);
      enqueueSnackbar(errorMessage, { variant: "error" });
    } finally {
      actions.setSubmitting(false);
    }
  };

  useEffect(() => {
    const load = async () => {
      try {
        if (!id) {
          return;
        }
        setLoading(true);
        const s = await DataStore.query(MScreen, id);
        setScreen(s);
      } catch (error) {
        if (error instanceof Error) {
          enqueueSnackbar(error.message, { variant: "error" });
        } else {
          console.log('Unexpected error', error);
        }
      } finally {
        setLoading(false);
      }
    };
    load();
  }, [enqueueSnackbar, id]);

  const handleRemove = async () => {
    if (screen) {
      await DataStore.delete(screen);
      enqueueSnackbar("Screen wurde gelöscht!", { variant: "success" });
      navigate(`/packzonen/screens`);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={
        {
          name: screen?.name,
          mode: screen?.mode,
        } as FormValues
      }
      validationSchema={Yup.object().shape({})}
      onSubmit={(values, actions) => {
        save(values, actions);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setValues,
        isSubmitting,
        touched,
        values,
        isValid,
      }) => (
        <Page
          loading={loading}
          className={classes.root}
          title="Edit Screen"
          breadcrumbs={[
            {
              title: "Screens",
              link: "/screens",
            },
            {
              title: screen && screen.name ? screen.name : "...",
              link: `/screens/${screen?.id}`,
            },
          ]}
          actions={[
            {
              title: "Cancel",
              icon: <CancelIcon />,
              action: () => navigate(`/packzonen/screens/${screen?.id}`),
              disabled: isSubmitting || !isValid,
            },
            {
              title: "Löschen",
              icon: <DeleteIcon />,
              color: "secondary",
              action: () => handleRemove(),
              disabled: isSubmitting || !isValid,
            },
            {
              title: "Save",
              icon: <SaveIcon />,
              action: () => handleSubmit(),
              disabled:
                isSubmitting || !isValid || Object.keys(touched).length === 0,
            },
          ]}
        >
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <Paper>
                <div className={classes.content}>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <Typography color="primary" className={classes.name}>
                        ID
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography color="textPrimary" className={classes.name}>
                        {screen?.deviceID}
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
                <div className={classes.content}>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <Typography color="primary" className={classes.name}>
                        Name
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        id="name"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                </div>
              </Paper>
            </Grid>
            <Grid item md={6} xs={12}>
              <Paper>
                <div className={classes.content}>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <Typography color="primary" className={classes.name}>
                        Modus
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <FormControl className={classes.formControl}>
                        {values && (
                          <Select
                            id="mode"
                            name="mode"
                            value={values.mode}
                            onChange={(
                              event: React.ChangeEvent<{ value: unknown }>
                            ) => {
                              if (event.target.value) {
                                setValues({
                                  mode: event.target.value as ScreenMode,
                                });
                              } else {
                                setValues({
                                  mode: undefined,
                                });
                              }
                            }}
                          >
                            <MenuItem value="PROJECTS">Projekte</MenuItem>
                            <MenuItem value="ZONES">Zonen</MenuItem>
                            <MenuItem value="OVERVIEW">Überblick</MenuItem>
                            <MenuItem disabled={true} value="VIRGIN">
                              Neu
                            </MenuItem>
                          </Select>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                </div>
              </Paper>
            </Grid>
          </Grid>
        </Page>
      )}
    </Formik>
  );
};

export default ScreenEdit;

import * as Yup from "yup";

import { Field, Formik, FormikHelpers } from "formik";
import {
  Grid,
  MenuItem,
  Paper,
  Select,
  Typography,
  makeStyles,
} from "@material-ui/core";
import {
  ProjectLane as MProject,
  ProjectSize,
  ProjectStatus,
  SubrentSize,
} from "../../models";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";

import CancelIcon from "@material-ui/icons/Cancel";
import { DataStore } from "aws-amplify";
import { DateTime } from "luxon";
import DeleteIcon from "@material-ui/icons/Delete";
import FormikDateTimePicker from "../../components/FormikDateTimePicker";
import Page from "../../components/Page";
import SaveIcon from "@material-ui/icons/Save";
import { TextField } from "@material-ui/core";
import prepareAPIError from "../../utils/prepareAPIError";
import { useSnackbar } from "notistack";

type FormValues = Pick<MProject, "title" | "subtitle" | "size"> & {
  subrent: string;
  subrentSize: SubrentSize | keyof typeof SubrentSize;
  outAt: DateTime;
  status: ProjectStatus | keyof typeof ProjectStatus;
};

const getStatusFromString = (status: string) => {
  if (status === "APPROVED") return ProjectStatus.APPROVED;
  if (status === "CONFIRMED") return ProjectStatus.CONFIRMED;
  if (status === "LOADED") return ProjectStatus.LOADED;
  if (status === "LOADING") return ProjectStatus.LOADING;
  if (status === "LOADING25") return ProjectStatus.LOADING25;
  if (status === "LOADING50") return ProjectStatus.LOADING50;
  if (status === "LOADING75") return ProjectStatus.LOADING75;
  if (status === "OUT") return ProjectStatus.OUT;
  if (status === "PACKED") return ProjectStatus.PACKED;
  if (status === "PACKING") return ProjectStatus.PACKING;
  if (status === "PACKING25") return ProjectStatus.PACKING25;
  if (status === "PACKING50") return ProjectStatus.PACKING50;
  if (status === "PACKING75") return ProjectStatus.PACKING75;
};

const getSizeFromString = (status: string) => {
  if (status === "S") return ProjectSize.S;
  if (status === "M") return ProjectSize.M;
  if (status === "L") return ProjectSize.L;
  if (status === "XL") return ProjectSize.XL;
  if (status === "XXL") return ProjectSize.XXL;
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  stack: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "40px",
  },
  paper: {
    width: 200,
    height: 230,
    overflow: "auto",
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
  card: {
    width: 240,
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  input: {
    // padding: theme.spacing(0.5, 2),
  },
  list: {
    width: "100%",
    height: 230,
    backgroundColor: theme.palette.background.paper,
    overflow: "auto",
  },
  content: {
    borderBottom: "1px solid rgb(224, 224, 224)",
    textAlign: "left",
    padding: "16px",
  },
  email_verified: {
    marginLeft: "16px",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  name: {
    lineHeight: "48px",
  },
  zone: {
    float: "left",
  },
}));

const ProjectEdit: FunctionComponent = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();

  const [loading, setLoading] = useState<boolean>(true);
  const [project, setProject] = useState<MProject | undefined>();

  useEffect(() => {
    const load = async () => {
      try {
        if (!id) {
          return;
        }
        setLoading(true);
        const s = await DataStore.query(MProject, id);
        setProject(s);
      } catch (error) {
        if (error instanceof Error) {
          enqueueSnackbar(error.message, { variant: "error" });
        } else {
          console.log("Unexpected error", error);
        }
      } finally {
        setLoading(false);
      }
    };
    load();
  }, [enqueueSnackbar, id]);

  const save = async (v: FormValues, actions: FormikHelpers<FormValues>) => {
    try {
      actions.setSubmitting(true);
      if (!project) return;
      await DataStore.save(
        MProject.copyOf(project, (updated) => {
          updated.title = v.title ? v.title.trim() : updated.title;
          updated.subtitle = v.subtitle ? v.subtitle.trim() : updated.subtitle;
          updated.outAt = v.outAt ? v.outAt.toISO() : updated.outAt;
          updated.status = v.status ? v.status : updated.status;
          updated.size = v.size ? v.size : updated.size;
          updated.subrent = v.subrent === "true";
          updated.subrentSize =  v.subrentSize ? v.subrentSize : updated.subrentSize;
        })
      );

      // // new
      // var b = new Set(oldZoneProjects);
      // const newZoneProjects = v.zones.filter((x) => !b.has(x));
      // if (av_zones) {
      //   await Promise.all(
      //     newZoneProjects.map((id) => {
      //       const zone = av_zones.find((a) => a.id === id);
      //       if (!zone) throw new Error("Zone not found");
      //       return DataStore.save(
      //         new MProjectZone({
      //           project: res,
      //           zone,
      //         })
      //       );
      //     })
      //   );
      // }

      // // remove
      // var c = new Set(v.zones);
      // const removeZoneProjects = oldZoneProjects.filter((x) => !c.has(x));
      // console.log(removeZoneProjects);
      // await Promise.all(
      //   removeZoneProjects.map((id) => {
      //     const projectZone = opc.find((a) => a.zone.id === id);
      //     if (!projectZone) throw new Error("projectZone not found");
      //     return DataStore.delete(projectZone);
      //   })
      // );

      enqueueSnackbar("Änderungen wurden gespeichert!", { variant: "success" });
      actions.resetForm();
      navigate(`/projekte`);
    } catch (error) {
      const errorMessage = prepareAPIError(error);
      enqueueSnackbar(errorMessage, { variant: "error" });
    } finally {
      actions.setSubmitting(false);
    }
  };

  const handleRemove = async () => {
    if (project) {
      await DataStore.delete(project);
      enqueueSnackbar("Belegung wurde gelöscht!", { variant: "success" });
      navigate(`/projekte`);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={
        {
          title: project?.title,
          subtitle: project?.subtitle,
          subrent: project?.subrent ? "true" : "false",
          subrentSize: project?.subrentSize ?? SubrentSize.NONE,
          status: getStatusFromString(project?.status || "CONFIRMED"),
          size: getSizeFromString(project?.size || "XL"),
          outAt: DateTime.fromISO(project?.outAt ?? ""),
        } as FormValues
      }
      validationSchema={Yup.object().shape({
        title: Yup.string().required("Name is required").min(4),
        zones: Yup.array().min(1),
      })}
      onSubmit={(values, actions) => {
        save(values, actions);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        isValid,
      }) => (
        <Page
          loading={isSubmitting || loading}
          className={classes.root}
          title="Belegung ändern"
          breadcrumbs={[]}
          actions={[
            {
              title: "Abbruch",
              icon: <CancelIcon />,
              action: () => navigate(`/projekte`),
              disabled: isSubmitting || !isValid,
            },
            {
              title: "Löschen",
              icon: <DeleteIcon />,
              color: "secondary",
              action: () => handleRemove(),
              disabled: isSubmitting || !isValid,
            },
            {
              title: "Speichern",
              icon: <SaveIcon />,
              color: "primary",
              action: () => handleSubmit(),
              disabled:
                isSubmitting || !isValid || Object.keys(touched).length === 0,
            },
          ]}
        >
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <Paper>
                <div className={classes.content}>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <Typography color="primary" className={classes.name}>
                        Status
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Select
                        disabled={isSubmitting}
                        className={classes.input}
                        error={Boolean(touched.status && errors.status)}
                        fullWidth
                        name="status"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        value={values.status}
                        variant="standard"
                        required
                      >
                        <MenuItem value={ProjectStatus.CONFIRMED}>
                          Auftrag bestätigt
                        </MenuItem>
                        <MenuItem value={ProjectStatus.APPROVED}>
                          Freigabe Material packen
                        </MenuItem>
                        <MenuItem value={ProjectStatus.PACKING}>
                          Packen begonnen
                        </MenuItem>
                        <MenuItem value={ProjectStatus.PACKING25}>
                          Packen 25%
                        </MenuItem>
                        <MenuItem value={ProjectStatus.PACKING50}>
                          Packen 50%
                        </MenuItem>
                        <MenuItem value={ProjectStatus.PACKING75}>
                          Packen 75%
                        </MenuItem>
                        <MenuItem value={ProjectStatus.PACKED}>
                          Packen beendet
                        </MenuItem>
                        <MenuItem value={ProjectStatus.LOADING}>
                          Laden begonnen
                        </MenuItem>
                        <MenuItem value={ProjectStatus.LOADING25}>
                          Laden 25%
                        </MenuItem>
                        <MenuItem value={ProjectStatus.LOADING50}>
                          Laden 50%
                        </MenuItem>
                        <MenuItem value={ProjectStatus.LOADING75}>
                          Laden 75%
                        </MenuItem>
                        <MenuItem value={ProjectStatus.LOADED}>
                          Laden beendet
                        </MenuItem>
                        <MenuItem value={ProjectStatus.OUT}>
                          On the Road
                        </MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <Typography color="primary" className={classes.name}>
                        Projektname
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        disabled={isSubmitting}
                        className={classes.input}
                        error={Boolean(touched.title && errors.title)}
                        fullWidth
                        helperText={touched.title && errors.title}
                        name="title"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        value={values.title}
                        variant="standard"
                        required
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <Typography color="primary" className={classes.name}>
                        Projektleiter
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        disabled={isSubmitting}
                        className={classes.input}
                        error={Boolean(touched.subtitle && errors.subtitle)}
                        fullWidth
                        helperText={touched.subtitle && errors.subtitle}
                        name="subtitle"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        value={values.subtitle}
                        variant="standard"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <Typography color="primary" className={classes.name}>
                        Bis
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Field component={FormikDateTimePicker} name="outAt" />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <Typography color="primary" className={classes.name}>
                        Projektaufwand (Mannstunden)
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Select
                        disabled={isSubmitting}
                        className={classes.input}
                        error={Boolean(touched.size && errors.size)}
                        fullWidth
                        name="size"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        value={values.size}
                        variant="standard"
                        required
                      >
                        <MenuItem value={ProjectSize.M}>Van (Doblo)</MenuItem>
                        <MenuItem value={ProjectSize.L}>Bus (Crafter)</MenuItem>
                        <MenuItem value={ProjectSize.XL}>7,5t LKW</MenuItem>
                        <MenuItem value={ProjectSize.XXL}>40t LKW</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <Typography color="primary" className={classes.name}>
                        Zumietung
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Select
                        disabled={isSubmitting}
                        className={classes.input}
                        error={Boolean(
                          touched.subrentSize && errors.subrentSize
                        )}
                        fullWidth
                        name="subrentSize"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        value={values.subrentSize}
                        variant="standard"
                        required
                      >
                        <MenuItem value={SubrentSize.NONE}>Nein</MenuItem>
                        <MenuItem value={SubrentSize.S}>1 Stunde</MenuItem>
                        <MenuItem value={SubrentSize.M}>2 Stunde</MenuItem>
                        <MenuItem value={SubrentSize.L}>4 Stunde</MenuItem>
                        <MenuItem value={SubrentSize.XL}>8 Stunde</MenuItem>
                        <MenuItem value={SubrentSize.XXL}>10 Stunde</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                  {values.subrentSize !== SubrentSize.NONE && (
                    <Grid container spacing={3}>
                      <Grid item xs={4}>
                        <Typography color="primary" className={classes.name}>
                          Zumietung abgeholt
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Select
                          disabled={isSubmitting}
                          className={classes.input}
                          error={Boolean(touched.subrent && errors.subrent)}
                          fullWidth
                          name="subrent"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="text"
                          value={values.subrent}
                          variant="standard"
                          required
                        >
                          <MenuItem value={"false"}>Nein</MenuItem>
                          <MenuItem value={"true"}>Ja</MenuItem>
                        </Select>
                      </Grid>
                    </Grid>
                  )}
                </div>
              </Paper>
            </Grid>
          </Grid>
        </Page>
      )}
    </Formik>
  );
};

export default ProjectEdit;

import { DateTimePicker } from "@material-ui/pickers";
import { FieldProps } from "formik";

const FormikDateTimePicker = ({
  form: { setFieldValue },
  field: { name, value },
  ...rest
}: FieldProps) => {
  // console.log(rest);
  return (
    <DateTimePicker
      name={name}
      clearable
      autoOk
      fullWidth
      cancelLabel="Schließen"
      disablePast={true}
      format="HH:mm 'am' dd.MM.yyyy"
      variant="dialog"
      emptyLabel="Kein Ende"
      clearLabel="Leeren"
      ampm={false}
      onChange={(value) => {
        setFieldValue(name, value);
      }}
      value={value}
      animateYearScrolling={false}
    />
  );
};

export default FormikDateTimePicker;

import { Grid, makeStyles } from "@material-ui/core";
import React, { FunctionComponent, useCallback, useRef, useState } from "react";

import { DataStore } from "aws-amplify";
import { ProjectLane as MProject } from "../../models";
import Page from "../../components/Page";
import ProjectItem from "../projects/ProjectItem";
import { orderBy } from "lodash";
import { useEffect } from "react";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  productCard: {
    height: "100%",
  },
  tableHeader: {
    backgroundColor: theme.palette.grey[300],
  },
  tableCell: {
    cursor: "pointer",
  },
  tableFooter: {
    flex: 1,
    display: "flex",
    justifyContent: "flex-end",
  },
  zone: {
    padding: theme.spacing(3),
  },
}));

const Projects: FunctionComponent = () => {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const [projects, setProjects] = useState<MProject[]>([]);
  const itemsRef = useRef<(HTMLDivElement | null)[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const loadData = useCallback(async () => {
    try {
      setLoading(true);
      const s = await DataStore.query(MProject);
      setProjects(orderBy(s, ["outAt"], ["asc"]));
      setScrollIndex(-1);
    } catch (error) {
      if (error instanceof Error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } else {
        console.log("Unexpected error", error);
      }
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  // subscribe to data strean
  useEffect(() => {
    const subscription = DataStore.observe(MProject).subscribe(() =>
      loadData()
    );
    return () => subscription.unsubscribe();
  }, [loadData]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      loadData();
    }, 60000);
    return () => clearInterval(intervalId);
  }, [loadData]);

  const [scrollIndex, setScrollIndex] = useState<number>(-1);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const newIndex = (scrollIndex + 1) % projects.length;
      setScrollIndex(newIndex);
      if (itemsRef.current[newIndex]) {
        itemsRef.current[newIndex]?.scrollIntoView();
      }
    }, 20000);
    return () => clearInterval(intervalId);
  }, [projects.length, scrollIndex]);

  return (
    <Page
      loading={loading}
      className={classes.root}
      title=""
      showPageTitle={false}
      actions={[]}
    >
      <Grid container spacing={3}>
        {projects.map((project, i) => (
          <Grid ref={(el) => (itemsRef.current[i] = el)} key={i} item xs={12}>
            <ProjectItem handleRowClick={(o) => {}} project={project} />
          </Grid>
        ))}
      </Grid>
    </Page>
  );
};

export default Projects;

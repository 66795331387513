import { DataGrid, GridColDef } from "@material-ui/data-grid";
import { Grid, Paper, makeStyles } from "@material-ui/core";
import React, { FunctionComponent, useState } from "react";

import { DataStore } from "aws-amplify";
import { Screen as MScreen } from "../../models";
import Page from "../../components/Page";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  productCard: {
    height: "100%",
  },
  tableHeader: {
    backgroundColor: theme.palette.grey[300],
  },
  tableCell: {
    cursor: "pointer",
  },
  tableFooter: {
    flex: 1,
    display: "flex",
    justifyContent: "flex-end",
  },
}));
const ScreensView: FunctionComponent = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const [screens, setScreens] = useState<MScreen[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const baseCol: Partial<GridColDef> = {
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
    headerClassName: classes.tableHeader,
    cellClassName: classes.tableCell,
  };

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", hide: true },
    {
      field: "deviceID",
      headerName: "ID",
      flex: 2,
      ...baseCol,
    },
    {
      field: "name",
      headerName: "Device",
      flex: 2,
      ...baseCol,
    },
    {
      field: "mode",
      headerName: "Modus",
      flex: 1,
      ...baseCol,
    },
  ];

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        const s = await DataStore.query(MScreen);
        setScreens(s);
      } catch (error) {
        if (error instanceof Error) {
          enqueueSnackbar(error.message, { variant: "error" });
        } else {
          console.log('Unexpected error', error);
        }
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, [enqueueSnackbar]);

  const handleRowClick = (d: string) => {
    navigate(`/packzonen/screens/${d}`);
  };

  return (
    <Page
      loading={loading}
      className={classes.root}
      title="Screens"
      actions={[]}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper>
            <DataGrid
              loading={loading}
              disableSelectionOnClick={true}
              hideFooterRowCount={true}
              rows={screens}
              columns={columns}
              autoHeight={true}
              pageSize={100}
              onRowClick={(rowData) => handleRowClick(rowData.row.id)}
              page={0}
            />
          </Paper>
        </Grid>
      </Grid>
    </Page>
  );
};

export default ScreensView;

import { Grid, Paper, Typography, makeStyles } from "@material-ui/core";
import React, { FunctionComponent, useState } from "react";
import { useNavigate, useParams } from "react-router";

import { DataStore } from "aws-amplify";
import EditIcon from "@material-ui/icons/Edit";
import { Screen as MScreen } from "../../models";
import Page from "../../components/Page";
import { useEffect } from "react";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  stack: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "40px",
  },
  paper: {
    width: 200,
    height: 230,
    overflow: "auto",
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
  card: {
    width: 240,
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  input: {
    padding: theme.spacing(0.5, 2),
  },
  list: {
    width: "100%",
    height: 230,
    backgroundColor: theme.palette.background.paper,
    overflow: "auto",
  },
  content: {
    borderBottom: "1px solid rgb(224, 224, 224)",
    textAlign: "left",
    padding: "16px",
  },
  email_verified: {
    marginLeft: "16px",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  name: {
    lineHeight: "48px",
  },
}));

const ScreenView: FunctionComponent = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();

  const [screen, setScreen] = useState<MScreen | undefined>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const load = async () => {
      try {
        if (!id) {
          return;
        }
        setLoading(true);
        const s = await DataStore.query(MScreen, id);
        setScreen(s);
      } catch (error) {
        if (error instanceof Error) {
          enqueueSnackbar(error.message, { variant: "error" });
        } else {
          console.log("Unexpected error", error);
        }
      } finally {
        setLoading(false);
      }
    };
    load();
  }, [enqueueSnackbar, id]);

  const getTitle = () => {
    if (!screen) return "...";
    if (screen.name) return screen.name || "";
    return screen.deviceID;
  };

  return (
    <Page
      loading={loading}
      className={classes.root}
      title="Screen"
      breadcrumbs={[
        {
          title: "Screens",
          link: "/screens",
        },
        {
          title: getTitle(),
          link: `/screens/${screen?.id}`,
        },
      ]}
      actions={[
        {
          title: "Edit",
          icon: <EditIcon />,
          hidden: !screen,
          action: () => {
            navigate(`/packzonen/screens/${screen?.id}/edit`);
          },
        },
      ]}
    >
      <Grid container spacing={3}>
        <Grid item md={6} xs={12}>
          <Paper>
            <div className={classes.content}>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <Typography color="primary" className={classes.name}>
                    ID
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography color="textPrimary" className={classes.name}>
                    {screen?.deviceID}
                  </Typography>
                </Grid>
              </Grid>
            </div>
            <div className={classes.content}>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <Typography color="primary" className={classes.name}>
                    Name
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography color="textPrimary" className={classes.name}>
                    {screen?.name}
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </Paper>
        </Grid>
        <Grid item md={6} xs={12}>
          <Paper>
            <div className={classes.content}>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <Typography color="primary" className={classes.name}>
                    Modus
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  {screen?.mode === "PROJECTS" && (
                    <Typography className={classes.name} color="textPrimary">
                      Projekte
                    </Typography>
                  )}
                  {screen?.mode === "ZONES" && (
                    <Typography className={classes.name} color="textPrimary">
                      Zonen
                    </Typography>
                  )}
                  {screen?.mode === "OVERVIEW" && (
                    <Typography className={classes.name} color="textPrimary">
                      Überblick
                    </Typography>
                  )}
                  {screen?.mode !== "PROJECTS" &&
                    screen?.mode !== "ZONES" &&
                    screen?.mode !== "OVERVIEW" && (
                      <Typography className={classes.name} color="textPrimary">
                        Aus
                      </Typography>
                    )}
                </Grid>
              </Grid>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </Page>
  );
};

export default ScreenView;

import {
  Breadcrumbs,
  Button,
  Container,
  Grid,
  Hidden,
  HiddenProps,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React, { FunctionComponent } from "react";

import { Helmet } from "react-helmet";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Link } from "react-router-dom";

interface PageProps {
  title: string;
  showPageTitle?: boolean;
  loading?: boolean;
  className?: string;
  breadcrumbs?: PageBreadcrumb[];
  actions?: PageAction[];
}

type DivProps = React.ComponentPropsWithoutRef<"div">;

const useStyles = makeStyles((theme) => ({
  stack: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "40px",
  },
  separator: {
    width: "4px",
    height: "4px",
    borderRadius: "50%",
    backgroundColor: "rgb(145, 158, 171)",
  },
  button: {
    margin: theme.spacing(1),
  },
  title: {
    lineHeight: "52px",
  },
}));

interface PageBreadcrumb {
  title: string;
  link: string;
}

interface PageAction {
  color?: "inherit" | "default" | "primary" | "secondary" | undefined;
  title: string;
  icon?: JSX.Element;
  disabled?: boolean;
  hidden?: boolean;
  hideOn?: HiddenProps;
  action: () => void;
}

// eslint-disable-next-line react/display-name
const Page: FunctionComponent<PageProps & DivProps> = ({
  actions,
  breadcrumbs,
  children,
  loading,
  title = "",
  showPageTitle = true,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <>
      {loading && <LinearProgress color="secondary" />}
      <div {...rest}>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <Container maxWidth="xl">
          {(actions || showPageTitle) && (
            <div className={classes.stack}>
              <Grid container justifyContent="space-between" spacing={3}>
                <Grid item>
                  {showPageTitle && (
                    <Typography
                      variant="h4"
                      component="h1"
                      className={classes.title}
                    >
                      {title}
                    </Typography>
                  )}
                </Grid>
                {actions && !loading && (
                  <Grid item>
                    {actions
                      .filter((a) => !a.hidden)
                      .map(({ color, title, icon, action, hideOn }, i) =>
                        hideOn ? (
                          <Hidden key={i} {...hideOn}>
                            <Button
                              key={i}
                              onClick={() => action()}
                              variant="contained"
                              color={color}
                              className={classes.button}
                              startIcon={icon}
                            >
                              {title}
                            </Button>
                          </Hidden>
                        ) : (
                          <Button
                            key={i}
                            onClick={() => action()}
                            variant="contained"
                            color={color}
                            className={classes.button}
                            startIcon={icon}
                          >
                            {title}
                          </Button>
                        )
                      )}
                  </Grid>
                )}
              </Grid>
              {breadcrumbs && (
                <Breadcrumbs
                  separator={<span className={classes.separator}></span>}
                  aria-label="breadcrumb"
                >
                  {breadcrumbs.slice(0, -1).map((breadcrumb, i) => (
                    <Link key={i} to={breadcrumb.link}>
                      {breadcrumb.title}
                    </Link>
                  ))}
                  {breadcrumbs.slice(-1).map((breadcrumb, i) => (
                    <Link key={i} to={breadcrumb.link} aria-current="page">
                      {breadcrumb.title}
                    </Link>
                  ))}
                </Breadcrumbs>
              )}
            </div>
          )}
          {children}
        </Container>
      </div>
    </>
  );
};

export default Page;

// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const ProjectSize = {
  "S": "S",
  "M": "M",
  "L": "L",
  "XL": "XL",
  "XXL": "XXL"
};

const SubrentSize = {
  "NONE": "NONE",
  "S": "S",
  "M": "M",
  "L": "L",
  "XL": "XL",
  "XXL": "XXL"
};

const ProjectStatus = {
  "CONFIRMED": "CONFIRMED",
  "APPROVED": "APPROVED",
  "PACKING": "PACKING",
  "PACKING25": "PACKING25",
  "PACKING50": "PACKING50",
  "PACKING75": "PACKING75",
  "LOADED": "LOADED",
  "PACKED": "PACKED",
  "LOADING": "LOADING",
  "OUT": "OUT",
  "LOADING25": "LOADING25",
  "LOADING50": "LOADING50",
  "LOADING75": "LOADING75"
};

const OccupancyStatus = {
  "RESERVED": "RESERVED",
  "PACKING": "PACKING",
  "COMPLETE": "COMPLETE",
  "MISSING": "MISSING",
  "RETURNED": "RETURNED",
  "RECEIVED": "RECEIVED",
  "PARKED": "PARKED"
};

const ScreenMode = {
  "VIRGIN": "VIRGIN",
  "ZONES": "ZONES",
  "PROJECTS": "PROJECTS",
  "OVERVIEW": "OVERVIEW"
};

const { ProjectLane, Occupancy, Zone, Screen, ScreenZone, OccupancyZone, TodoProject, Todo, TodoTemplateProject, TodoTemplate } = initSchema(schema);

export {
  ProjectLane,
  Occupancy,
  Zone,
  Screen,
  ScreenZone,
  OccupancyZone,
  TodoProject,
  Todo,
  TodoTemplateProject,
  TodoTemplate,
  ProjectSize,
  SubrentSize,
  ProjectStatus,
  OccupancyStatus,
  ScreenMode
};
import { DateTime, Duration, DurationUnit } from "luxon";
import { Grid, Paper, Step, StepLabel, Stepper } from "@material-ui/core";
import { ProjectLane, ProjectSize, ProjectStatus } from "../../models";

import { FunctionComponent } from "react";
import { SubrentSize } from "../../models";
import styled from "styled-components";

// import { Stepper } from "./stepper";

const workHoursStart = 9;
const workHoursEnd = 17;
const workHours = workHoursEnd - workHoursStart;

interface ProjectItemProps {
  project: ProjectLane;
  handleRowClick?: (occupancy: ProjectLane) => void;
}

const SPM = styled.div`
  text-align: center !important;
  color: #222200;
  font-weight: bold;
  text-align: left;
  font-size: 21px;
  line-height: 21px;
  height: 100%;
  width: 100%;
  padding: 4px 21px 21px 21px;
`;

const SPT = styled.div`
  text-align: center !important;
  color: #666666;
  text-align: left;
  font-size: 18px;
  line-height: 18px;
  width: 100%;
  padding: 21px 21px 4px 21px;
`;

const statusToString = (status: any) => {
  if (status === ProjectStatus.CONFIRMED) {
    return "Auftrag bestätigt";
  }
  if (status === ProjectStatus.APPROVED) {
    return "Freigabe Packen";
  }
  if (status === ProjectStatus.PACKING) {
    return "Packen begonnen";
  }
  if (status === ProjectStatus.PACKING25) {
    return "25%";
  }
  if (status === ProjectStatus.PACKING50) {
    return "50%";
  }
  if (status === ProjectStatus.PACKING75) {
    return "75%";
  }
  if (status === ProjectStatus.PACKED) {
    return "Packen beendet";
  }
  if (status === ProjectStatus.LOADING) {
    return "Laden begonnen";
  }
  if (status === ProjectStatus.LOADING25) {
    return "25%";
  }
  if (status === ProjectStatus.LOADING50) {
    return "50%";
  }
  if (status === ProjectStatus.LOADING75) {
    return "75%";
  }
  if (status === ProjectStatus.LOADED) {
    return "Laden beendet";
  }
  if (status === ProjectStatus.OUT) {
    return "On the Road";
  }
  return "";
};

const sizeToString = (size: any) => {
  if (size === ProjectSize.M) {
    return "Van (Doblo)";
  }
  if (size === ProjectSize.L) {
    return "Bus (Crafter)";
  }
  if (size === ProjectSize.XL) {
    return "7,5t LKW";
  }
  if (size === ProjectSize.XXL) {
    return "40t LKW";
  }
  return "";
};

const calculateTotalHours = (size: any, subrentSize: any) => {
  let totalPoints: number = 0;
  totalPoints += PointsPackingSize[size as ProjectSize] as number;
  totalPoints += PointsLoadingSize[size as ProjectSize] as number;
  totalPoints += PointsSubrentSize[subrentSize as SubrentSize] as number;
  return totalPoints;
};

const calculateHoursDone = (
  size: ProjectSize | any,
  status: ProjectStatus | any,
  subrent: SubrentSize | any,
  subrentDoneBoolean: boolean
) => {
  let totalPoints: number = 0;

  debugger;

  const hoursPackingStep =
    (PointsPackingSize[size as ProjectSize] as number) / 4;
  const hoursLoadingStep =
    (PointsLoadingSize[size as ProjectSize] as number) / 4;
  const pointsSubrent = PointsSubrentSize[subrent as SubrentSize] as number;
  if (status === ProjectStatus.OUT) {
    totalPoints += 0;
  }
  if (status === ProjectStatus.OUT || status === ProjectStatus.LOADED) {
    totalPoints += hoursLoadingStep;
  }
  if (
    status === ProjectStatus.OUT ||
    status === ProjectStatus.LOADED ||
    status === ProjectStatus.LOADING75
  ) {
    totalPoints += hoursLoadingStep;
  }
  if (
    status === ProjectStatus.OUT ||
    status === ProjectStatus.LOADED ||
    status === ProjectStatus.LOADING75 ||
    status === ProjectStatus.LOADING50
  ) {
    totalPoints += hoursLoadingStep;
  }
  if (
    status === ProjectStatus.OUT ||
    status === ProjectStatus.LOADED ||
    status === ProjectStatus.LOADING75 ||
    status === ProjectStatus.LOADING50 ||
    status === ProjectStatus.LOADING25
  ) {
    totalPoints += hoursLoadingStep;
  }
  if (
    status === ProjectStatus.OUT ||
    status === ProjectStatus.LOADED ||
    status === ProjectStatus.LOADING75 ||
    status === ProjectStatus.LOADING50 ||
    status === ProjectStatus.LOADING25 ||
    status === ProjectStatus.LOADING
  ) {
    totalPoints += 0;
  }
  if (
    status === ProjectStatus.OUT ||
    status === ProjectStatus.LOADED ||
    status === ProjectStatus.LOADING75 ||
    status === ProjectStatus.LOADING50 ||
    status === ProjectStatus.LOADING25 ||
    status === ProjectStatus.LOADING ||
    status === ProjectStatus.PACKED
  ) {
    totalPoints += hoursPackingStep;
  }
  if (
    status === ProjectStatus.OUT ||
    status === ProjectStatus.LOADED ||
    status === ProjectStatus.LOADING75 ||
    status === ProjectStatus.LOADING50 ||
    status === ProjectStatus.LOADING25 ||
    status === ProjectStatus.LOADING ||
    status === ProjectStatus.PACKED ||
    status === ProjectStatus.PACKING75
  ) {
    totalPoints += hoursPackingStep;
  }
  if (
    status === ProjectStatus.OUT ||
    status === ProjectStatus.LOADED ||
    status === ProjectStatus.LOADING75 ||
    status === ProjectStatus.LOADING50 ||
    status === ProjectStatus.LOADING25 ||
    status === ProjectStatus.LOADING ||
    status === ProjectStatus.PACKED ||
    status === ProjectStatus.PACKING75 ||
    status === ProjectStatus.PACKING50
  ) {
    totalPoints += hoursPackingStep;
  }
  if (
    status === ProjectStatus.OUT ||
    status === ProjectStatus.LOADED ||
    status === ProjectStatus.LOADING75 ||
    status === ProjectStatus.LOADING50 ||
    status === ProjectStatus.LOADING25 ||
    status === ProjectStatus.LOADING ||
    status === ProjectStatus.PACKED ||
    status === ProjectStatus.PACKING75 ||
    status === ProjectStatus.PACKING50 ||
    status === ProjectStatus.PACKING25
  ) {
    totalPoints += hoursPackingStep;
  }

  if (
    status === ProjectStatus.OUT ||
    status === ProjectStatus.LOADED ||
    status === ProjectStatus.LOADING75 ||
    status === ProjectStatus.LOADING50 ||
    status === ProjectStatus.LOADING25 ||
    status === ProjectStatus.LOADING ||
    status === ProjectStatus.PACKED ||
    status === ProjectStatus.PACKING75 ||
    status === ProjectStatus.PACKING50 ||
    status === ProjectStatus.PACKING25 ||
    status === ProjectStatus.PACKING
  ) {
    totalPoints += 0;
  }

  if (
    status === ProjectStatus.OUT ||
    status === ProjectStatus.LOADED ||
    status === ProjectStatus.LOADING75 ||
    status === ProjectStatus.LOADING50 ||
    status === ProjectStatus.LOADING25 ||
    status === ProjectStatus.LOADING ||
    status === ProjectStatus.PACKED ||
    status === ProjectStatus.PACKING75 ||
    status === ProjectStatus.PACKING50 ||
    status === ProjectStatus.PACKING25 ||
    status === ProjectStatus.PACKING ||
    status === ProjectStatus.CONFIRMED
  ) {
    totalPoints += 0;
  }

  if (
    status === ProjectStatus.OUT ||
    status === ProjectStatus.LOADED ||
    status === ProjectStatus.LOADING75 ||
    status === ProjectStatus.LOADING50 ||
    status === ProjectStatus.LOADING25 ||
    status === ProjectStatus.LOADING ||
    status === ProjectStatus.PACKED ||
    status === ProjectStatus.PACKING75 ||
    status === ProjectStatus.PACKING50 ||
    status === ProjectStatus.PACKING25 ||
    status === ProjectStatus.PACKING ||
    status === ProjectStatus.CONFIRMED ||
    status === ProjectStatus.APPROVED
  ) {
    totalPoints += 0;
  }

  if (subrentDoneBoolean) {
    totalPoints += pointsSubrent;
  }

  return totalPoints;
};

const steps: ProjectStatus[] = [
  ProjectStatus.CONFIRMED,
  ProjectStatus.APPROVED,
  ProjectStatus.PACKING,
  ProjectStatus.PACKING25,
  ProjectStatus.PACKING50,
  ProjectStatus.PACKING75,
  ProjectStatus.PACKED,
  ProjectStatus.LOADING,
  ProjectStatus.LOADING25,
  ProjectStatus.LOADING50,
  ProjectStatus.LOADING75,
  ProjectStatus.LOADED,
  ProjectStatus.OUT,
];

const CPapger = styled(Paper)<{
  hasOK: boolean;
  hasWarning: boolean;
  hasDanger: boolean;
}>`
  background-color: ${(props) => (props.hasOK ? "#79ff4c" : "")};
  background-color: ${(props) => (props.hasWarning ? "#eba134" : "")};
  background-color: ${(props) => (props.hasDanger ? "#ff4f4c" : "")};
  margin-bottom: 24px;
`;

const ProjectItem: FunctionComponent<ProjectItemProps> = (props) => {
  const outAtDT = DateTime.fromISO(props.project.outAt);
  const outAt = outAtDT.toLocaleString({
    weekday: "short",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });

  const total = calculateTotalHours(
    props.project.size ?? ProjectSize.S,
    props.project.subrentSize ?? SubrentSize.NONE
  );

  const activeStep = steps.indexOf(props.project.status as ProjectStatus);

  let hoursToDeadline = workingHoursBetweenDates(
    new Date(),
    outAtDT.toJSDate()
  );

  let doneHours = calculateHoursDone(
    props.project.size ?? ProjectSize.S,
    props.project.status ?? ProjectStatus.CONFIRMED,
    props.project.subrentSize ?? SubrentSize.NONE,
    props.project.subrent ?? false
  );

  let hoursRestWork = total - doneHours;

  const thresholdWarning = hoursToDeadline * 0.5;
  const hasWarning = hoursRestWork > thresholdWarning;

  const thresholdDanger = hoursToDeadline * 0.9;
  const hasDanger = hoursRestWork > thresholdDanger;

  // const units: DurationUnit[] =
  //   hoursToDeadline < 8 ? ["hours", "minutes"] : ["days", "hours"];

  const units: DurationUnit[] = ["hours", "minutes"];

  if (hoursToDeadline >= 24) {
    hoursToDeadline = Math.round(hoursToDeadline * 10) / 10;
  }

  const durationDeadline = Duration.fromObject({ hours: hoursToDeadline });

  if (hoursRestWork >= 24) {
    hoursRestWork = Math.round(hoursRestWork * 10) / 10;
  }

  const durationDone = Duration.fromObject({ hours: hoursRestWork });

  const durationToHuman = (duration: Duration) => {
    const _hours = duration.as("hours");
    // const days = Math.floor(_hours / workHours);
    // const hours = _hours % workHours;
    const hours2 = Math.floor(_hours);
    const minutes = (_hours - hours2) * 60;

    const parts = [];

    // if (days > 0) {
    //   parts.push(days);
    //   parts.push("ATg.");
    // }

    parts.push(Math.round(hours2 * 10) / 10);
    parts.push("Std.");

    parts.push(Math.round(minutes * 10) / 10);
    parts.push("Min.");

    return parts.join(" ");
  };

  return (
    <CPapger
      hasWarning={hasWarning}
      hasDanger={hasDanger}
      hasOK={
        props.project.status && props.project.status === ProjectStatus.APPROVED
      }
      variant="elevation"
      onClick={() =>
        props.handleRowClick && props.handleRowClick(props.project)
      }
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={2}>
          <SPT>Projekt</SPT>
          <SPM>{props.project.title}</SPM>
        </Grid>
        <Grid item xs={12} md={2}>
          <SPT>Projektaufwand</SPT>
          <SPM>{sizeToString(props.project.size)}</SPM>
          {/* <SPM>{total}</SPM> */}
        </Grid>
        <Grid item xs={12} md={2}>
          <SPT>Projektleiter</SPT>
          <SPM>{props.project.subtitle}</SPM>
        </Grid>
        <Grid item xs={12} md={2}>
          <SPT>Deadline</SPT>
          <SPM>{outAt}</SPM>
        </Grid>
        <Grid item xs={12} md={2}>
          <SPT>Restaufwand Mannstunden</SPT>
          <SPM>
            {durationToHuman(durationDone)}
            {/* {durationDone
              .shiftTo(...units)
              .toHuman({ unitDisplay: "short", listStyle: "short" })} */}
          </SPM>
        </Grid>
        <Grid item xs={12} md={2}>
          <SPT>verfügbare Arbeitszeit</SPT>
          <SPM>
            {durationToHuman(durationDeadline)}
            {/* {durationDeadline
              .shiftTo(...units)
              .toHuman({ unitDisplay: "short", listStyle: "short" })} */}
          </SPM>
        </Grid>
      </Grid>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((s: ProjectStatus, i: number) => {
          const label = statusToString(s);
          return (
            <Step key={i}>
              <StepLabel>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {props.project.subrentSize &&
        props.project.subrentSize !== SubrentSize.NONE && (
          <Stepper
            activeStep={props.project.subrent === true ? 1 : 0}
            alternativeLabel
          >
            <Step key="requiresSubrent">
              <StepLabel>Zumietung</StepLabel>
            </Step>
            <Step key="subrent">
              <StepLabel>Geholt</StepLabel>
            </Step>
          </Stepper>
        )}
    </CPapger>
  );
};

export default ProjectItem;

const PointsPackingSize: Record<ProjectSize, number> = {
  S: 0.5,
  M: 1,
  L: 4,
  XL: 10,
  XXL: 16,
};

const PointsLoadingSize: Record<ProjectSize, number> = {
  S: 0.1,
  M: 0.5,
  L: 1,
  XL: 3,
  XXL: 6,
};

const PointsSubrentSize: Record<SubrentSize, number> = {
  NONE: 0,
  S: 1,
  M: 2,
  L: 4,
  XL: 8,
  XXL: 10,
};

// Simple function that accepts two parameters and calculates the number of hours worked
// within that range
function workingHoursBetweenDates(startDate: Date, endDate: Date): number {
  // Store minutes worked
  let minutesWorked = 0;

  // Validate input
  if (endDate < startDate) {
    return 0;
  }

  // Loop from your Start to End dates (by hour)
  let current = startDate;

  // Define work range
  var includeWeekends = false;

  // Loop while currentDate is less than end Date (by minutes)
  while (current <= endDate) {
    // Is the current time within a work day (and if it occurs on a weekend or not)
    if (
      current.getHours() >= workHoursStart &&
      current.getHours() <= workHoursEnd &&
      (includeWeekends
        ? current.getDay() !== 0 && current.getDay() !== 6
        : true)
    ) {
      minutesWorked++;
    }

    // Increment current time
    current.setTime(current.getTime() + 1000 * 60);
  }

  // Return the number of hours
  return minutesWorked / 60;
}

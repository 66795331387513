import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
} from "@material-ui/core";
import { Link as RouterLink, useNavigate } from "react-router-dom";

import PlusIcon from "@material-ui/icons/Add";
import React from "react";
import clsx from "clsx";
import styled from "styled-components";
import { useLocation } from "react-router";

interface TopBarProps {
  className?: string;
  onMobileNavOpen: () => void;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    width: 60,
    height: 60,
  },
  canarybadge: {
    display: "flex",
    alignItems: "center",
    padding: "2px 8px",
    margin: "0 .5rem",
    borderRadius: 4,
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
    paddingTop: "0.125rem",
    paddingBottom: "0.125rem",
    // fontWeight: "600",
  },
  logo: {},
}));

const Head = styled.h1<{ active: boolean }>`
  margin: 0;
  padding: 0;
  color: ${({ active }) => (active ? "white" : "gray ")} !important;
  font-size: ${({ active }) => (active ? "2rem" : "1rem ")} !important;
  height: 52px;
  a {
    height: ${({ active }) => (active ? "auto" : "52px ")} !important;
    display: flex;
    align-items: center;
    padding: 2px 8px;
    margin: 5px 2px 5px -8px;
    border-radius: 4px;
    color: ${({ active }) => (active ? "white" : "gray ")} !important;
    font-size: ${({ active }) => (active ? "2rem" : "1rem ")} !important;
  }
`;

const TopBar = ({ className, onMobileNavOpen }: TopBarProps) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  console.log();
  console.log();
  return (
    <AppBar className={clsx(classes.root, className)} elevation={0}>
      <Toolbar>
        <Head active={location.pathname.includes("/packzonen")}>
          <RouterLink to="/packzonen">Packzonen</RouterLink>
        </Head>
        <Head active={location.pathname.includes("/projekte")}>
          <RouterLink to="/projekte">Projekte</RouterLink>
        </Head>
        <Head active={location.pathname.includes("/todos")}>
          <RouterLink to="/todos">Aufgaben</RouterLink>
        </Head>
        <Box flexGrow={1} />
        <Hidden mdUp>
          <IconButton
            color="inherit"
            onClick={() => navigate(`/packzonen/o/new`)}
          >
            <PlusIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;

import * as Yup from "yup";

import {
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Field, FieldArray, FieldProps, Formik, FormikHelpers } from "formik";
import {
  ScreenZone as MSZ,
  Screen as MScreen,
  Zone as MZone,
} from "../../models";
import React, { FunctionComponent, useEffect, useState } from "react";

import CancelIcon from "@material-ui/icons/Cancel";
import { DataStore } from "aws-amplify";
import Page from "../../components/Page";
import SaveIcon from "@material-ui/icons/Save";
import { TextField } from "@material-ui/core";
import prepareAPIError from "../../utils/prepareAPIError";
import { useNavigate } from "react-router";
import { useSnackbar } from "notistack";

interface FormValues {
  name: string;
  sort?: number;
  screens: string[];
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  stack: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "40px",
  },
  paper: {
    width: 200,
    height: 230,
    overflow: "auto",
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
  card: {
    width: 240,
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  input: {
    padding: theme.spacing(0.5, 2),
  },
  list: {
    width: "100%",
    height: 230,
    backgroundColor: theme.palette.background.paper,
    overflow: "auto",
  },
  content: {
    borderBottom: "1px solid rgb(224, 224, 224)",
    textAlign: "left",
    padding: "16px",
  },
  email_verified: {
    marginLeft: "16px",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  name: {
    lineHeight: "48px",
  },
}));
const ZoneCreate: FunctionComponent = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [av_screens, setAVScreens] = useState<MScreen[] | undefined>();

  useEffect(() => {
    const load = () => {
      DataStore.query(MScreen).then((screens: MScreen[]) => {
        setAVScreens(screens);
      });
    };
    load();
  }, []);

  const save = async (v: FormValues, actions: FormikHelpers<FormValues>) => {
    try {
      actions.setSubmitting(true);
      const res = await DataStore.save(
        new MZone({
          name: v.name,
          sort: v.sort,
        })
      );
      if (av_screens) {
        await Promise.all(
          v.screens.map((id) => {
            const screen = av_screens.find((a) => a.id === id);
            if (!screen) throw new Error("Screen not found");
            return DataStore.save(
              new MSZ({
                zone: res,
                screen,
              })
            );
          })
        );
      }
      enqueueSnackbar("Änderungen wurden gespeichert!", { variant: "success" });
      actions.resetForm();
      navigate(`/packzonen/zones/${res.id}`);
    } catch (error) {
      const errorMessage = prepareAPIError(error);
      enqueueSnackbar(errorMessage, { variant: "error" });
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={
        {
          name: "",
          screens: [],
        } as FormValues
      }
      validationSchema={Yup.object().shape({
        name: Yup.string().required("Name is required").min(4),
      })}
      onSubmit={(values, actions) => {
        save(values, actions);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        isValid,
      }) => (
        <Page
          loading={isSubmitting}
          className={classes.root}
          title="Create a Zone"
          breadcrumbs={[
            {
              title: "Zones",
              link: "/zones",
            },
            {
              title: "new",
              link: `/zones/new`,
            },
          ]}
          actions={[
            {
              title: "Cancel",
              icon: <CancelIcon />,
              action: () => navigate(`/packzonen/zones`),
              disabled: isSubmitting || !isValid,
            },
            {
              title: "Save",
              icon: <SaveIcon />,
              action: () => handleSubmit(),
              disabled:
                isSubmitting || !isValid || Object.keys(touched).length === 0,
            },
          ]}
        >
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <Paper>
                <div className={classes.content}>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <Typography color="primary" className={classes.name}>
                        Name
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        disabled={isSubmitting}
                        className={classes.input}
                        error={Boolean(touched.name && errors.name)}
                        fullWidth
                        helperText={touched.name && errors.name}
                        name="name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        value={values.name}
                        variant="outlined"
                        required
                      />
                    </Grid>
                  </Grid>
                </div>
              </Paper>
            </Grid>
            <Grid item md={6} xs={12}>
              <Paper>
                <div className={classes.content}>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <Typography color="primary" className={classes.name}>
                        Sort
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        disabled={isSubmitting}
                        className={classes.input}
                        error={Boolean(touched.sort && errors.sort)}
                        fullWidth
                        helperText={touched.sort && errors.sort}
                        name="sort"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="number"
                        value={values.sort}
                        variant="outlined"
                        required
                      />
                    </Grid>
                  </Grid>
                </div>
              </Paper>
            </Grid>
            <Grid item md={6} xs={12}>
              <Paper>
                <div className={classes.content}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography color="primary" className={classes.name}>
                        Screen
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <div role="group">
                        <FieldArray
                          name="screens"
                          render={(arrayHelpers) => (
                            <div>
                              {av_screens?.map((avs, index) => (
                                <div key={index}>
                                  <Field type="checkbox" name="screens">
                                    {({
                                      field: { name, value, onChange, onBlur },
                                    }: FieldProps) => (
                                      <>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={value.includes(avs.id)}
                                              onChange={onChange}
                                              onBlur={onBlur}
                                              name={name}
                                              value={avs.id}
                                              color="primary"
                                            />
                                          }
                                          label={avs.name || avs.id}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              ))}
                            </div>
                          )}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Paper>
            </Grid>
          </Grid>
        </Page>
      )}
    </Formik>
  );
};

export default ZoneCreate;

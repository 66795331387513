import { Grid, makeStyles } from "@material-ui/core";
import React, { FunctionComponent, useCallback, useState } from "react";

import AddIcon from "@material-ui/icons/Add";
import { DataStore } from "aws-amplify";
import { ProjectLane as MProject } from "../../models";
import Page from "../../components/Page";
import ProjectItem from "./ProjectItem";
import { orderBy } from "lodash";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  productCard: {
    height: "100%",
  },
  tableHeader: {
    backgroundColor: theme.palette.grey[300],
  },
  tableCell: {
    cursor: "pointer",
  },
  tableFooter: {
    flex: 1,
    display: "flex",
    justifyContent: "flex-end",
  },
  zone: {
    padding: theme.spacing(3),
  },
}));

const ProjectsView: FunctionComponent = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const [projects, setProjects] = useState<MProject[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const loadData = useCallback(async () => {
    try {
      setLoading(true);
      const s = await DataStore.query(MProject);
      setProjects(orderBy(s, ["outAt"], ["asc"]));
    } catch (error) {
      if (error instanceof Error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } else {
        console.log("Unexpected error", error);
      }
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  // subscribe to data strean
  useEffect(() => {
    const subscription = DataStore.observe(MProject).subscribe(() =>
      loadData()
    );
    return () => subscription.unsubscribe();
  }, [loadData]);

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     loadData();
  //   }, 60000);
  //   return () => clearInterval(intervalId);
  // }, [loadData]);

  const handleRowClick = (d: string) => {
    navigate(`/projekte/o/${d}`);
  };

  return (
    <Page
      loading={loading}
      className={classes.root}
      title=""
      showPageTitle={false}
      actions={[
        {
          title: "Neues Projekt",
          icon: <AddIcon />,
          color: "primary",
          action: () => navigate(`/projekte/o/new`),
          hideOn: { smDown: true },
        },
      ]}
    >
      <Grid container spacing={3}>
        {projects.map((project, i) => (
          <Grid key={i} item xs={12}>
            <ProjectItem
              handleRowClick={(o) => handleRowClick(o.id)}
              project={project}
            />
          </Grid>
        ))}
      </Grid>
    </Page>
  );
};

export default ProjectsView;

import { Field, FieldArray, Formik, FormikHelpers } from "formik";
import { Button, Grid, Paper, Typography, makeStyles } from "@material-ui/core";
import { TodoProject as MTodoProject, Todo as MTodo } from "../../models";
import { FunctionComponent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";

import CancelIcon from "@material-ui/icons/Cancel";
import { DataStore } from "aws-amplify";
import { DateTime } from "luxon";
import DeleteIcon from "@material-ui/icons/Delete";
import FormikDatePicker from "../../components/FormikDatePicker";
import Page from "../../components/Page";
import SaveIcon from "@material-ui/icons/Save";
import { TextField, Checkbox } from "@material-ui/core";
import prepareAPIError from "../../utils/prepareAPIError";
import { useSnackbar } from "notistack";

type FormValues = Pick<MTodoProject, "title" | "subtitle" | "lead"> & {
  outAt?: DateTime;
  todos: MTodo[];
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  stack: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "40px",
  },
  paper: {
    width: 200,
    height: 230,
    overflow: "auto",
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
  card: {
    width: 240,
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  input: {
    // padding: theme.spacing(0.5, 2),
  },
  list: {
    width: "100%",
    height: 230,
    backgroundColor: theme.palette.background.paper,
    overflow: "auto",
  },
  content: {
    borderBottom: "1px solid rgb(224, 224, 224)",
    textAlign: "left",
    padding: "16px",
  },
  email_verified: {
    marginLeft: "16px",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  name: {
    lineHeight: "48px",
  },
  zone: {
    float: "left",
  },
}));

const ProjectEdit: FunctionComponent = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();

  const [loading, setLoading] = useState<boolean>(true);
  const [project, setProject] = useState<MTodoProject | undefined>();
  const [todos, setTodos] = useState<MTodo[] | undefined>([]);

  useEffect(() => {
    const load = async () => {
      try {
        if (!id) {
          return;
        }
        setLoading(true);

        const s = await DataStore.query(MTodoProject, id);
        setProject(s);

        const t = await DataStore.query(MTodo, (c) => c.project.id.eq(id));

        setTodos(t);
      } catch (error) {
        if (error instanceof Error) {
          enqueueSnackbar(error.message, { variant: "error" });
        } else {
          console.log("Unexpected error", error);
        }
      } finally {
        setLoading(false);
      }
    };
    load();
  }, [enqueueSnackbar, id]);

  const save = async (v: FormValues, actions: FormikHelpers<FormValues>) => {
    try {
      actions.setSubmitting(true);

      if (!project) return;

      await DataStore.save(
        MTodoProject.copyOf(project, (updated) => {
          updated.title = v.title ? v.title.trim() : updated.title;
          updated.subtitle = v.subtitle ? v.subtitle.trim() : updated.subtitle;
          updated.outAt = v.outAt ? v.outAt.toISO() : updated.outAt;
        })
      );

      // save all todos
      await Promise.all(
        v.todos
          // .filter((t) => t.title.length > 0)
          .map(async (t) => {
            const or = todos?.find((x) => x.id === t.id);
            if (or) {
              await DataStore.save(
                MTodo.copyOf(or, (updated) => {
                  updated.done = t.done;
                  updated.updatedAt = DateTime.local().toISO();
                })
              );
            } else {
              await DataStore.save(
                new MTodo({
                  title: t.title ? t.title.trim() : "",
                  done: t.done,
                  project: project,
                  createdAt: DateTime.local().toISO(),
                  updatedAt: DateTime.local().toISO(),
                })
              );
            }
          })
      );

      // delete todos
      await Promise.all(
        todos
          ?.filter((t) => !v.todos.find((x) => x.id === t.id))
          .map(async (t) => {
            if (t.id) {
              await DataStore.delete(t);
            }
          }) ?? []
      );

      enqueueSnackbar("Projekt wurden gespeichert!", { variant: "success" });
      actions.resetForm();
      navigate(`/todos`);
    } catch (error) {
      const errorMessage = prepareAPIError(error);
      enqueueSnackbar(errorMessage, { variant: "error" });
    } finally {
      actions.setSubmitting(false);
    }
  };

  const handleRemove = async () => {
    if (project) {
      await DataStore.delete(project);
      enqueueSnackbar("Projekt wurde gelöscht!", { variant: "success" });
      navigate(`/todos`);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={
        {
          title: project?.title,
          subtitle: project?.subtitle,
          lead: project?.lead,
          outAt: project?.outAt ? DateTime.fromISO(project?.outAt) : undefined,
          todos: todos,
        } as FormValues
      }
      onSubmit={(values, actions) => {
        save(values, actions);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        isValid,
      }) => (
        <Page
          loading={isSubmitting || loading}
          className={classes.root}
          title="Projekt ändern"
          breadcrumbs={[]}
          actions={[
            {
              title: "Abbruch",
              icon: <CancelIcon />,
              action: () => navigate(`/todos`),
              disabled: isSubmitting || !isValid,
            },
            {
              title: "Löschen",
              icon: <DeleteIcon />,
              color: "secondary",
              action: () => handleRemove(),
              disabled: isSubmitting || !isValid,
            },
            {
              title: "Speichern",
              icon: <SaveIcon />,
              color: "primary",
              action: () => handleSubmit(),
              disabled:
                isSubmitting || !isValid || Object.keys(touched).length === 0,
            },
          ]}
        >
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <Paper>
                <div className={classes.content}>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <Typography color="primary" className={classes.name}>
                        Projektname
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        disabled={isSubmitting}
                        className={classes.input}
                        error={Boolean(touched.title && errors.title)}
                        fullWidth
                        helperText={touched.title && errors.title}
                        name="title"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        value={values.title}
                        variant="standard"
                        required
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <Typography color="primary" className={classes.name}>
                        Projektnummer
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        disabled={isSubmitting}
                        className={classes.input}
                        error={Boolean(touched.subtitle && errors.subtitle)}
                        fullWidth
                        helperText={touched.subtitle && errors.subtitle}
                        name="subtitle"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        value={values.subtitle}
                        variant="standard"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <Typography color="primary" className={classes.name}>
                        Projektleiter
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        disabled={isSubmitting}
                        className={classes.input}
                        error={Boolean(touched.lead && errors.lead)}
                        fullWidth
                        helperText={touched.lead && errors.lead}
                        name="lead"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        value={values.lead}
                        variant="standard"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <Typography color="primary" className={classes.name}>
                        Bis
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Field component={FormikDatePicker} name="outAt" />
                    </Grid>
                  </Grid>
                </div>
              </Paper>
            </Grid>
            <Grid item md={6} xs={12}>
              <Paper>
                <div className={classes.content}>
                  <FieldArray
                    name="todos"
                    render={(arrayHelpers) => (
                      <>
                        {values.todos
                          ?.sort((a, b) => (a.sort ?? 0) - (b.sort ?? 0))
                          .map((todo, index) => (
                            <Grid key={index} container spacing={3}>
                              <Grid item xs={1}>
                                <Checkbox
                                  name={`todos[${index}].done`}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  disabled={isSubmitting}
                                  checked={todo.done}
                                />
                              </Grid>
                              <Grid item xs={10}>
                                <input
                                  type="hidden"
                                  name={`todos[${index}].id`}
                                  value={todo.id}
                                />
                                {todo.id && <span>{todo.title}</span>}
                                {!todo.id && (
                                  <TextField
                                    disabled={isSubmitting}
                                    className={classes.input}
                                    error={Boolean(
                                      touched.title && errors.title
                                    )}
                                    fullWidth
                                    helperText={touched.title && errors.title}
                                    name={`todos[${index}].title`}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="text"
                                    value={todo.title}
                                    variant="standard"
                                    required
                                  />
                                )}
                              </Grid>
                              <Grid item xs={1}>
                                <Button
                                  size="small"
                                  type="button"
                                  variant="outlined"
                                  color="primary"
                                  disabled={isSubmitting}
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  -
                                </Button>
                              </Grid>
                            </Grid>
                          ))}
                        <Grid container spacing={3}>
                          <Grid item xs={11}>
                            <Button
                              disabled={isSubmitting}
                              className={classes.input}
                              variant="outlined"
                              color="primary"
                              onClick={() =>
                                arrayHelpers.push({ title: "", done: false })
                              }
                            >
                              Neue Aufgabe
                            </Button>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  />
                </div>
              </Paper>
            </Grid>
          </Grid>
        </Page>
      )}
    </Formik>
  );
};

export default ProjectEdit;

import * as Yup from "yup";

import { Field, Formik, FormikHelpers } from "formik";
import {
  Grid,
  MenuItem,
  Paper,
  Select,
  Typography,
  makeStyles,
} from "@material-ui/core";
import {
  ProjectLane as MProject,
  ProjectSize,
  SubrentSize,
} from "../../models";
import React, { FunctionComponent, useEffect, useState } from "react";

import CancelIcon from "@material-ui/icons/Cancel";
import { DataStore } from "aws-amplify";
import { DateTime } from "luxon";
import FormikDateTimePicker from "../../components/FormikDateTimePicker";
import Page from "../../components/Page";
import { ProjectStatus } from "../../models";
import SaveIcon from "@material-ui/icons/Save";
import { TextField } from "@material-ui/core";
import prepareAPIError from "../../utils/prepareAPIError";
import { useNavigate } from "react-router";
import { useSnackbar } from "notistack";

type FormValues = Pick<MProject, "title" | "subtitle" | "size"> & {
  subrentSize: SubrentSize | keyof typeof SubrentSize;
  outAt: DateTime;
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  stack: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "40px",
  },
  paper: {
    width: 200,
    height: 230,
    overflow: "auto",
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
  card: {
    width: 240,
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  input: {
    // padding: theme.spacing(0.5, 2),
  },
  list: {
    width: "100%",
    height: 230,
    backgroundColor: theme.palette.background.paper,
    overflow: "auto",
  },
  content: {
    borderBottom: "1px solid rgb(224, 224, 224)",
    textAlign: "left",
    padding: "16px",
  },
  email_verified: {
    marginLeft: "16px",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  name: {
    lineHeight: "48px",
  },
  zone: {
    float: "left",
  },
}));

const ProjectCreate: FunctionComponent = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [av_zones, setAVZones] = useState<MProject[] | undefined>();

  useEffect(() => {
    const load = async () => {
      DataStore.query(MProject).then((projects: MProject[]) => {
        setAVZones(
          projects.sort((a, b) =>
            `${a.title}-${a.subtitle}`.localeCompare(
              `${b.title}-${b.subtitle}`,
              "de",
              {
                numeric: true,
              }
            )
          )
        );
      });
    };
    load();
  }, []);

  const save = async (v: FormValues, actions: FormikHelpers<FormValues>) => {
    try {
      actions.setSubmitting(true);
      debugger;
      const res = await DataStore.save(
        new MProject({
          title: v.title,
          subtitle: v.subtitle,
          subrentSize: v.subrentSize,
          outAt: v.outAt?.toISO(),
          status: ProjectStatus.CONFIRMED,
          size: v.size as ProjectSize,
        })
      );
      enqueueSnackbar("Änderungen wurden gespeichert!", { variant: "success" });
      actions.resetForm();
      navigate(`/projekte/o/${res.id}`);
    } catch (error) {
      const errorMessage = prepareAPIError(error);
      enqueueSnackbar(errorMessage, { variant: "error" });
    } finally {
      actions.setSubmitting(false);
    }
  };

  console.log(av_zones);

  return (
    <Formik
      enableReinitialize
      initialValues={
        {
          title: "",
          subtitle: "",
          subrentSize: SubrentSize.NONE,
          size: ProjectSize.M,
          outAt: DateTime.local(),
        } as FormValues
      }
      validationSchema={Yup.object().shape({
        title: Yup.string().required("Name is required").min(4),
        zones: Yup.array().min(1),
      })}
      onSubmit={(values, actions) => {
        save(values, actions);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        isValid,
      }) => (
        <Page
          loading={isSubmitting}
          className={classes.root}
          title="Neues Projekt"
          breadcrumbs={[]}
          actions={[
            {
              title: "Abbruch",
              icon: <CancelIcon />,
              action: () => navigate(`/projekte`),
              disabled: isSubmitting || !isValid,
            },
            {
              title: "Speichern",
              icon: <SaveIcon />,
              color: "primary",
              action: () => handleSubmit(),
              disabled:
                isSubmitting || !isValid || Object.keys(touched).length === 0,
            },
          ]}
        >
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <Paper>
                <div className={classes.content}>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <Typography color="primary" className={classes.name}>
                        Projektname
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        disabled={isSubmitting}
                        className={classes.input}
                        error={Boolean(touched.title && errors.title)}
                        fullWidth
                        helperText={touched.title && errors.title}
                        name="title"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        value={values.title}
                        variant="standard"
                        required
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <Typography color="primary" className={classes.name}>
                        Projektleiter
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        disabled={isSubmitting}
                        className={classes.input}
                        error={Boolean(touched.subtitle && errors.subtitle)}
                        fullWidth
                        helperText={touched.subtitle && errors.subtitle}
                        name="subtitle"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        value={values.subtitle}
                        variant="standard"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <Typography color="primary" className={classes.name}>
                        Bis
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Field component={FormikDateTimePicker} name="outAt" />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <Typography color="primary" className={classes.name}>
                        Projektaufwand (Mannstunden)
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Select
                        disabled={isSubmitting}
                        className={classes.input}
                        error={Boolean(touched.size && errors.size)}
                        fullWidth
                        name="size"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        value={values.size}
                        variant="standard"
                        required
                      >
                        <MenuItem value={ProjectSize.M}>Van (Doblo)</MenuItem>
                        <MenuItem value={ProjectSize.L}>Bus (Crafter)</MenuItem>
                        <MenuItem value={ProjectSize.XL}>7,5t LKW</MenuItem>
                        <MenuItem value={ProjectSize.XXL}>40t LKW</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <Typography color="primary" className={classes.name}>
                        Zumietung
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Select
                        disabled={isSubmitting}
                        className={classes.input}
                        error={Boolean(
                          touched.subrentSize && errors.subrentSize
                        )}
                        fullWidth
                        name="subrentSize"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        value={values.subrentSize}
                        variant="standard"
                        required
                      >
                        <MenuItem value={SubrentSize.NONE}>Nein</MenuItem>
                        <MenuItem value={SubrentSize.S}>1 Stunde</MenuItem>
                        <MenuItem value={SubrentSize.M}>2 Stunde</MenuItem>
                        <MenuItem value={SubrentSize.L}>4 Stunde</MenuItem>
                        <MenuItem value={SubrentSize.XL}>8 Stunde</MenuItem>
                        <MenuItem value={SubrentSize.XXL}>10 Stunde</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                </div>
              </Paper>
            </Grid>
          </Grid>
        </Page>
      )}
    </Formik>
  );
};

export default ProjectCreate;

import "./Screen.scss";

import {
  Screen as MScreen,
  ScreenZone as MScreenZone,
  Zone as MZone,
} from "../../models";
import React, { FunctionComponent, useEffect, useState } from "react";

import { DataStore } from "aws-amplify";
import Zone from "./Zone";
import { orderBy } from "lodash";
import { useCallback } from "react";

interface ZonesProps {
  screen: MScreen;
}

export const Zones: FunctionComponent<ZonesProps> = ({ screen }) => {
  const [zones, setZones] = useState<MZone[]>([]);

  const loadZones = useCallback(() => {
    if (!screen) return;
    DataStore.query(MScreenZone, (sz) => sz.screen.id.eq(screen.id)).then(
      async (screenZones: MScreenZone[]) => {
        const zones = await Promise.all(
          screenZones.map(async (sz) => await sz.zone)
        );
        setZones(orderBy(zones, (z) => z.name));
      }
    );
  }, [screen]);

  useEffect(() => {
    loadZones();
  }, [loadZones]);

  useEffect(() => {
    const subscription = DataStore.observe(MZone).subscribe(() => loadZones());
    return () => subscription.unsubscribe();
  }, [loadZones]);

  useEffect(() => {
    const subscription = DataStore.observe(MScreenZone).subscribe(() =>
      loadZones()
    );
    return () => subscription.unsubscribe();
  }, [loadZones]);

  if (zones.length === 0) {
    return (
      <div className="screen">
        <div className="empty">
          <h1>Diesem Screen wurden keine Zonen zugewiesen!</h1>
          <h2>{screen.deviceID}</h2>
          {screen && screen.name && <h2>{screen && screen.name}</h2>}
        </div>
      </div>
    );
  } else {
    return (
      <div className="screen">
        {zones.map((zone) => (
          <Zone key={zone.id} zone={zone} />
        ))}
      </div>
    );
  }
};

import { Grid, makeStyles } from "@material-ui/core";
import React, { FunctionComponent, useState } from "react";

import AddIcon from "@material-ui/icons/Add";
import { DataStore } from "aws-amplify";
import { Occupancy as MOccupancy } from "../../models";
import OccupancyItem from "./OccupancyItem";
import Page from "../../components/Page";
import { orderBy } from "lodash";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  productCard: {
    height: "100%",
  },
  tableHeader: {
    backgroundColor: theme.palette.grey[300],
  },
  tableCell: {
    cursor: "pointer",
  },
  tableFooter: {
    flex: 1,
    display: "flex",
    justifyContent: "flex-end",
  },
  zone: {
    padding: theme.spacing(3),
  },
}));

const OccupanciesView: FunctionComponent = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const [occupancies, setOccupancies] = useState<MOccupancy[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        const s = await DataStore.query(MOccupancy);
        setOccupancies(orderBy(s, ["outAt", "nastatusme"], ["desc", "asc"]));
      } catch (error) {
        if (error instanceof Error) {
          enqueueSnackbar(error.message, { variant: "error" });
        } else {
          console.log("Unexpected error", error);
        }
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, [enqueueSnackbar]);

  const handleRowClick = (d: string) => {
    navigate(`/packzonen/o/${d}`);
  };

  return (
    <Page
      loading={loading}
      className={classes.root}
      title=""
      showPageTitle={false}
      actions={[
        {
          title: "Neue Belegung",
          icon: <AddIcon />,
          color: "primary",
          action: () => navigate(`/packzonen/o/new`),
          hideOn: { smDown: true },
        },
      ]}
    >
      <Grid container spacing={3}>
        {occupancies.map((occupancy, i) => (
          <Grid key={i} item xs={12}>
            <OccupancyItem
              handleRowClick={(o) => handleRowClick(o.id)}
              occupancy={occupancy}
            />
          </Grid>
        ))}
      </Grid>
    </Page>
  );
};

export default OccupanciesView;

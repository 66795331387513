import { DataGrid, GridColDef } from "@material-ui/data-grid";
import { Grid, Paper, makeStyles } from "@material-ui/core";
import React, { FunctionComponent, useState } from "react";

import AddIcon from "@material-ui/icons/Add";
import { DataStore } from "aws-amplify";
import { Zone as MZone } from "../../models";
import Page from "../../components/Page";
import ZoneScreensView from "./ZoneScreensView";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  productCard: {
    height: "100%",
  },
  tableHeader: {
    backgroundColor: theme.palette.grey[300],
  },
  tableCell: {
    cursor: "pointer",
  },
  tableFooter: {
    flex: 1,
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const ZonesView: FunctionComponent = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const [zones, setZones] = useState<MZone[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const baseCol: Partial<GridColDef> = {
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
    headerClassName: classes.tableHeader,
    cellClassName: classes.tableCell,
  };

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", hide: true },
    {
      field: "name",
      headerName: "Zone",
      flex: 1,
      ...baseCol,
    },
    {
      field: "sort",
      headerName: "Sort",
      flex: 1,
      ...baseCol,
    },
    {
      field: "screens",
      headerName: "Screens",
      flex: 2,
      renderCell: ({ row }) => {
        return <ZoneScreensView id={row.id} />;
      },
      ...baseCol,
    },
  ];

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        const s = await DataStore.query(MZone);
        setZones(
          s.sort((a, b) =>
            `${a.sort}-${a.name}`.localeCompare(`${b.sort}-${b.name}`, "de", {
              numeric: true,
            })
          )
        );
      } catch (error) {
        if (error instanceof Error) {
          enqueueSnackbar(error.message, { variant: "error" });
        } else {
          console.log('Unexpected error', error);
        }
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, [enqueueSnackbar]);

  const handleRowClick = (d: string) => {
    navigate(`/packzonen/zones/${d}`);
  };

  return (
    <Page
      loading={loading}
      className={classes.root}
      title="Zones"
      actions={[
        {
          title: "New Zone",
          icon: <AddIcon />,
          action: () => navigate(`/packzonen/zones/new`),
        },
      ]}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper>
            <DataGrid
              loading={loading}
              disableSelectionOnClick={true}
              hideFooterRowCount={true}
              rows={zones}
              columns={columns}
              autoHeight={true}
              pageSize={100}
              onRowClick={(rowData) => handleRowClick(rowData.row.id)}
              page={0}
            />
          </Paper>
        </Grid>
      </Grid>
    </Page>
  );
};

export default ZonesView;

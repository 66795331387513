import "./index.scss";

import { Amplify, Auth, DataStore } from "aws-amplify";

import App from "./App";
import { HashRouter } from "react-router-dom";
import React from "react";
import ReactDOM from "react-dom";
import awsconfig from "./aws-exports";

// Amplify.Logger.LOG_LEVEL = "DEBUG";

Amplify.configure(awsconfig);
Auth.configure(awsconfig);
DataStore.configure(awsconfig);

// DataStore.configure({
//   maxRecordsToSync: 30000,
//   fullSyncInterval: 1, // minutes
//   DataStore: {
//     fullSyncInterval: 1
//   }
// });

// setInterval(() => {
//   DataStore.clear().then(() => {
//     console.log("DataStore cleared");
//   });
// }, 120 * 60 * 60 * 1000);

DataStore.clear().then(() => {
  ReactDOM.render(
    <HashRouter>
      <App />
    </HashRouter>,
    document.getElementById("root")
  );
});

import { Chip, makeStyles } from "@material-ui/core";
import { FunctionComponent, useEffect, useState } from "react";
import { Screen as MScreen, ScreenZone as MScreenZone } from "../../models";

import { DataStore } from "aws-amplify";

const useZoneScreensViewStyles = makeStyles((theme) => ({
  ul: {},
  li: {
    display: "inline-block",
    marginRight: "12px",
  },
}));

interface ZoneScreensViewProps {
  id: string;
}

const getScreenForZone = (id: string) => {
  return DataStore.query(MScreenZone, (sz) => sz.zone.id.eq(id)).then(
    async (screenZones) => {
      return await Promise.all(screenZones.map((sz) => sz.screen));
    }
  );
};

const ZoneScreensView: FunctionComponent<ZoneScreensViewProps> = ({
  id,
}: ZoneScreensViewProps) => {
  const classes = useZoneScreensViewStyles();
  const [screens, setScreens] = useState<MScreen[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        const s = await getScreenForZone(id);
        setScreens(s);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, [id]);
  if (loading) return <p>Loading...</p>;
  return (
    <ul className={classes.ul}>
      {screens.map((s) => (
        <li className={classes.li}>
          <Chip label={s.name || s.deviceID} />
        </li>
      ))}
    </ul>
  );
};

export default ZoneScreensView;
